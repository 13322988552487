import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import mailIcon from '../../../assets/icons/mail.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import webIcon from '../../../assets/icons/web.svg';
import { PresentationContact } from '../../../types';
import { fadeInFromBottomAnimation, SlideDirection, slideInHalfAnimation } from '../../animations';
import { AspectRatio } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import {
  backgroundColorDark,
  contentColorDark,
  highlightColor,
  iconSize,
  slideImageStyle,
} from '../../styles';
import { Theme } from '../../types';

const Slide = styled.div`
  width: 100%;
  height: 100%;

  /** 
  * We create a grid to have the possibility two have one or two contacts.
  * The first and last rows are margins (which will be spanned by the image if shown).
  * There are three columns while the first and the last are 50% evenly spaced.
  * The image will be about 59.5% in space if shown.
  */
  display: grid;
  grid-template-columns: auto 9.8% 44.3%;
  grid-template-rows: 12.05% 45% auto 13%;

  background: ${backgroundColorDark};
  color: ${contentColorDark};
  text-transform: uppercase;
  font-weight: bold;

  padding-left: 7.4%;
  box-sizing: border-box;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};

  grid-column: 2 / span 2;
  grid-row: 1 / span 4;
`;

const Content = styled.div`
  grid-column: 1;
  grid-row: 2;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 4.77em;
  letter-spacing: 0.016em;
`;

const DividerBar = styled(motion.div)`
  margin-top: -0.13em;
  min-height: 0.2em;
  width: 19%;
  background: ${contentColorDark};
`;

const Divider = styled(motion.div)`
  width: 65%;
  margin-bottom: 0.3em;
  border: none;
  border-bottom: 1px solid ${contentColorDark};
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 4.9em;
`;

const ContactContent = styled(motion.div)`
  grid-row: 3;
  padding-right: 2%;

  &:nth-child(3) {
    grid-column: 3;
    padding-right: 7%;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  font-size: 1.44em;
  min-height: 1.2em;
`;

const Email = styled.div`
  font-size: 1em;
  line-height: 1.75em;

  text-transform: none;
  text-decoration: underline;
  color: ${highlightColor};

  background-image: url(${mailIcon});
  background-repeat: no-repeat;
  background-size: ${iconSize} ${iconSize};
  background-position-y: center;

  padding-left: calc(${iconSize} + 0.5em);
`;

const Phone = styled.div`
  font-size: 1em;
  line-height: 1.75em;

  background-image: url(${phoneIcon});
  background-repeat: no-repeat;
  background-size: ${iconSize} ${iconSize};
  background-position-y: center;

  padding-left: calc(${iconSize} + 0.5em);
`;

const Url = styled.div`
  font-size: 1em;
  line-height: 1.75em;

  background-image: url(${webIcon});
  background-repeat: no-repeat;
  background-size: ${iconSize} ${iconSize};
  background-position-y: center;

  padding-left: calc(${iconSize} + 0.5em);
  text-transform: lowercase;
`;

interface ContactComponentProps {
  contact: PresentationContact;
}

const ContactComponent: React.FC<ContactComponentProps> = ({ contact }) => {
  return (
    <div>
      <ContactInfo>
        <Info>{contact.academicTitle}</Info>
        <Info>
          {contact.firstName} {contact.lastName}
        </Info>
      </ContactInfo>
      <Email>{contact.email}</Email>
      <Phone>{contact.phone}</Phone>
      <Url>https://gross-bau.de</Url>
    </div>
  );
};

interface ThanksSlideProps {
  contacts: PresentationContact[];
  image: string;
}

const ThanksSlide: React.FC<ThanksSlideProps> = ({ contacts, image }) => {
  const [firstContact, secondContact] = contacts;
  const isSingleContact = contacts.length < 2;

  return (
    <AspectRatio>
      <Slide>
        <Content>
          <Title variants={slideInHalfAnimation()} initial="hidden" animate="show">
            Vielen
          </Title>
          <DividerBar
            variants={slideInHalfAnimation({ delay: 0.25 })}
            initial="hidden"
            animate="show"
          />
          <Divider variants={slideInHalfAnimation()} initial="hidden" animate="show" />
          <SubTitle variants={slideInHalfAnimation({ delay: 0.5 })} initial="hidden" animate="show">
            Dank
          </SubTitle>
        </Content>
        {firstContact && (
          <ContactContent variants={fadeInFromBottomAnimation(1)} initial="hidden" animate="show">
            <ContactComponent contact={firstContact} />
          </ContactContent>
        )}
        {secondContact && (
          <ContactContent
            variants={fadeInFromBottomAnimation(1.25)}
            initial="hidden"
            animate="show"
          >
            <ContactComponent contact={secondContact} />
          </ContactContent>
        )}
        {isSingleContact && (
          <Image
            variants={slideInHalfAnimation({ direction: SlideDirection.FromRight })}
            initial="hidden"
            animate="show"
            url={image}
          ></Image>
        )}
        <Logo theme={isSingleContact ? Theme.Dark : Theme.Light} />
      </Slide>
    </AspectRatio>
  );
};

export default ThanksSlide;
