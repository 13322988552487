import { motion } from 'framer-motion';
import React from 'react';
import { styled } from '../../../../../styles';
import { SlideDirection, slideInAnimation, slideOutAnimation } from '../../../../animations';
import {
  contentColor,
  descriptionTextStyle,
  highlightColor,
  subSubTitleTextStyle,
} from '../../../../styles';
import { Fact } from '../../../../types/sliced-fact';

const Container = styled.div`
  position: relative;
  display: flex;

  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ColumnValue = styled(motion.div)`
  ${subSubTitleTextStyle}
  position: absolute;
  font-size: 3em;
  color: ${highlightColor};
  font-weight: 900;
  letter-spacing: 0.04em;

  left: 3.1%;
  bottom: 30.2%;
`;

const ColumnDescription = styled(motion.div)`
  ${descriptionTextStyle}
  position: absolute;
  font-size: 0.968em;
  color: ${contentColor};
  font-weight: 900;
  left: 4%;
  bottom: 27.7%;
`;

const Divider = styled(motion.div)`
  width: 1px;
  background: ${highlightColor};
  z-index: 70;
  height: 75.18518%;
`;

export interface SlicedFactProps {
  fact: Fact;
  animationDelay: number;
}

export default function SlicedFact({ fact, animationDelay }: SlicedFactProps) {
  return (
    <Container>
      <Divider
        variants={slideInAnimation({ direction: SlideDirection.FromTop, delay: animationDelay })}
        initial="hidden"
        animate="show"
      />

      <ColumnDescription
        variants={slideInAnimation({
          direction: SlideDirection.FromBottom,
          delay: animationDelay + 0.3,
        })}
        initial="hidden"
        animate="show"
      >
        {fact.description}
      </ColumnDescription>

      <ColumnValue
        variants={slideInAnimation({ direction: SlideDirection.FromBottom, delay: animationDelay })}
        initial="hidden"
        animate="show"
      >
        {fact.value.toLocaleString( 'de-DE' )}
      </ColumnValue>
    </Container>
  );
}

export function SlicedFactBackwards({ fact, animationDelay }: SlicedFactProps) {
  return (
    <Container>

      <ColumnDescription
        variants={slideOutAnimation({
          direction: SlideDirection.FromBottom,
          delay: animationDelay + 0.3,
        })}
        initial="hidden"
        animate="show"
      >
        {fact.description}
      </ColumnDescription>

      <ColumnValue
        variants={slideOutAnimation({ direction: SlideDirection.FromBottom, delay: animationDelay })}
        initial="hidden"
        animate="show"
      >
        {fact.value.toLocaleString( 'de-DE' )}
      </ColumnValue>
    </Container>
  );
}

export function SlicedFactNoLine({ fact, animationDelay }: SlicedFactProps) {
  return (
    <Container>
     <Divider
      />
      <ColumnDescription
        variants={slideInAnimation({
          direction: SlideDirection.FromBottom,
          delay: animationDelay + 0.3,
        })}
        initial="hidden"
        animate="show"
      >
        {fact.description}
      </ColumnDescription>

      <ColumnValue
        variants={slideInAnimation({ direction: SlideDirection.FromBottom, delay: animationDelay })}
        initial="hidden"
        animate="show"
      >
        {fact.value.toLocaleString( 'de-DE' )}
      </ColumnValue>
    </Container>
  );
}
