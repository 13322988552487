import { wrap } from '@popmotion/popcorn';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import {
  NavigationDirection,
  PresentationSliderAnimations,
  PresentationSliderProps,
  PresentationSliderStyles as Styles,
} from './PresentationSlider.resources';

export default function PresentationSlider({
  slideComponents,
  isFullscreen,
  onFullscreenRequest,
}: PresentationSliderProps) {
  const [[page, direction], setPage] = useState([0, 'next']);
  const [hideMouse, setHideMouse] = useState(false);
  const slideIndex = wrap(0, slideComponents.length, page);
  const isLastSlide = slideIndex === slideComponents.length - 1;
  const CurrentSlide = () => slideComponents[slideIndex]!;
  const slidesWithFollowup = ["FactsSlide", "dA"];
    /* if(slideComponents[slideIndex] != null) {
    if(slidesWithFollowup.includes(slideComponents[slideIndex].type.name)) {
      hasFollowup = true;
    }
  } */
  const navigate = useCallback(
    (direction: NavigationDirection, number: number) => {
      //const newDirection = direction === 'previous' ? -1 : 1;
      setPage([page + number, direction]);
    },
    [page],
  );

  const reloadPage = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    window.location.reload();
  };

  useEffect(() => {
    // const hasFollowup = (number : number) => slideComponents[number] === null ? false : slidesWithFollowup.includes(slideComponents[number].type.name);
    const hasFollowup = (number : number) => number === 3;
    const navigateViaKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          if(hasFollowup(slideIndex - 1)) {
            navigate('noAnim', -1);
            setHideMouse(true);
            return;
          } else {
            navigate('previous', -1);
            setHideMouse(true);
          }
          break;
        case 'ArrowRight':
          if (!isLastSlide) {
            if(hasFollowup(slideIndex)) {
              navigate('noAnim', 1);
              setHideMouse(true);
              return;
            } else {
              navigate('next', 1);
              setHideMouse(true);
              return;
            }   
          }
          break;
      }
  };

    const navigateViaClick = (direction: NavigationDirection) => {
      if (!isLastSlide || direction === 'previous') {
          if(direction === 'next') {
            if (!isLastSlide) {
              if(hasFollowup(slideIndex)) {
                navigate('noAnim', 1);
                setHideMouse(false);
                return;
              } else {
                navigate('next', 1);
                setHideMouse(false);
                return;
              }   
            }
          }
          else {
            if(hasFollowup(slideIndex - 1)) {
            navigate('noAnim', -1);
            setHideMouse(false);
            return;
          } else {
            navigate('previous', -1);
            setHideMouse(false);
          }
        }
      }
    };

    const navigateViaClickForward = () => navigateViaClick('next');
    const navigateViaClickBackward = (e: MouseEvent) => {
      const isRightClick = e.button === 2;

      if (isRightClick) {
        return;
      }

      navigateViaClick('previous');
    };

    document.addEventListener('keydown', navigateViaKey);
    document.addEventListener('click', navigateViaClickForward);
    document.addEventListener('auxclick', navigateViaClickBackward);

    return () => {
      document.removeEventListener('keydown', navigateViaKey);
      document.removeEventListener('click', navigateViaClickForward);
      document.removeEventListener('auxclick', navigateViaClickBackward);
    };
  }, [isLastSlide, navigate, slideIndex, slidesWithFollowup, slideComponents]);

  return (
    <Styles.Container>
      <AnimatePresence initial={false} custom={direction}>
        <Styles.SlideContainer
          hideMouse={hideMouse && !isLastSlide}
          key={page}
          variants={PresentationSliderAnimations.variants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            // TODO: langju: delay slide animations to be rendered after slide-transition is completed
            // staggerChildren: 2,
            // Stagger childen works with child motion div elements
            opacity: { duration: 1 },
          }}
        >
          <CurrentSlide />
        </Styles.SlideContainer>
      </AnimatePresence>

      {!isFullscreen && (
        <Styles.FullscreenButton
          onClick={(e) => requestFullscreen(e)}
          title="Vollbildmodus aktivieren"
        >
          {'▧'}
        </Styles.FullscreenButton>
      )}
      {isLastSlide && (
        <Styles.PresentationHelper>
          <Styles.HelperButton onClick={() => navigate('next', 1)} title="Zurück zur ersten Folie">
            {'↺'}
          </Styles.HelperButton>
          <Styles.CloseButton onClick={(e) => reloadPage(e)} title="Präsentation beenden">
            {'⨉'}
          </Styles.CloseButton>
        </Styles.PresentationHelper>
      )}
    </Styles.Container>
  );

  function requestFullscreen(e: React.MouseEvent) {
    e.nativeEvent.stopImmediatePropagation();
    onFullscreenRequest?.();
  }
}
