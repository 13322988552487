import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { ReferenceDetailsSlideType } from '../../../types';
import {
  fadeInFromBottomAnimation,
  SlideDirection,
  slideInAnimation,
  slideInHalfAnimation,
} from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import Divider from '../shared/Divider/Divider';
import {
  catchPhraseTextStyle,
  contentColor,
  descriptionTextStyle,
  slideImageStyle,
  titleTextStyle,
} from '../../styles';
import { Theme } from '../../types';

// TODO: langju: auto-resize subtitles?

const Slide = styled.div`
  ${BaseSlideCss}
`;

const InnerSlide = styled(motion.div)`
  margin-top: 8.68%;
  margin-left: 7.2%;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  width: 89%;
  height: 94%;
`;

const LargeImage = styled(motion.div)`
  ${slideImageStyle};

  // width: 42.5%;
  width: 27.5%;
  height: 57.1%;

  flex-grow: 1;
`;

const Info = styled(motion.div)`
  color: ${contentColor};

  height: 50%;
  width: 35%;

  // margin-right: 15%;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 2%;
    margin-left: 6.9%;
  }
`;

const Title = styled(motion.div)`
  margin-top: -3.05%;
  letter-spacing: -0.01em;
  margin-left: 0.55em;

  ${titleTextStyle}

  span:nth-child(2) {
    color: ${contentColor};
  }
`;

const Description = styled(motion.div)`
  ${descriptionTextStyle}
  line-height: 1.45em;
  letter-spacing: -0.01em;
  margin-left:2.02em;
`;

const CatchPhrase = styled(motion.div)`
  ${catchPhraseTextStyle}

  letter-spacing: -0.01em;
  margin-top: 1.1em;
  margin-left:2.02em;
`;

const SmallImageContainer = styled(motion.div)`
  position: absolute;
  z-index: 20;
  height: 25.1%;
  width: 58.1%;
  bottom: 7.5%;
  right: 0px;
`;

const SmallImage = styled(motion.div)`
  ${slideImageStyle};
  height: 100%;
  width: 48.4%;
  margin-top: 0.5em;
`;

type ReferenceDetailsSlideProps = Omit<ReferenceDetailsSlideType, 'type'>;

const ReferenceDetailsSlide: React.FC<ReferenceDetailsSlideProps> = ({
  title,
  description,
  catchPhrase,
  largeImage,
  smallImage,
}) => {
  const titleWords = title.split(' ');
  return (
    <AspectRatio>
      <Slide>
        <SlideTitle>Referenzen</SlideTitle>
        <InnerSlide>
          <LargeImage
            url={largeImage}
            variants={slideInHalfAnimation()}
            initial="hidden"
            animate="show"
          ></LargeImage>
          <Info variants={fadeInFromBottomAnimation(0.25)} initial="hidden" animate="show">
            <Title>
              {titleWords.map((word, index) => (
                <span key={index}>{word} </span>
              ))}
            </Title>
            <Description variants={fadeInFromBottomAnimation(0.5)} initial="hidden" animate="show">
              {description}
            </Description>
            <CatchPhrase variants={fadeInFromBottomAnimation(0.5)} initial="hidden" animate="show">
              {catchPhrase}
            </CatchPhrase>
          </Info>
          <SmallImageContainer
            initial="hidden"
            animate="show"
            variants={slideInAnimation({
              direction: SlideDirection.FromRight,
              distance: '58.1vw',
            })}
          >
            <Divider fromDirection="right" delay={0.25}></Divider>
            <SmallImage url={smallImage} initial="hidden" animate="show"></SmallImage>
          </SmallImageContainer>
        </InnerSlide>
        <Logo theme={Theme.Dark} />
      </Slide>
    </AspectRatio>
  );
};

export default ReferenceDetailsSlide;
