import { Variants } from 'framer-motion';
import { SlideDirection } from './slide-direction';

export type FramerEasing =
  | 'linear'
  | 'easeIn'
  | 'easeOut'
  | 'easeInOut'
  | 'circIn'
  | 'circOut'
  | 'circInOut'
  | 'backIn'
  | 'backOut'
  | 'backInOut'
  | 'anticipate';

export interface SlideAnimationOpts {
  direction?: SlideDirection;
  delay?: number;
  ease?: FramerEasing;
  /**
   * A numerical expression including unit defining the distance over which the slide animation takes place.
   *
   * Example: For a full slide in animation use `"100vw"` (horizontal) or `"100vh"` (vertical).
   */
  distance?: string;
}

export const slideInHalfAnimation: (opts?: SlideAnimationOpts) => Variants = (opts) => {
  return slideInAnimation({ ...opts, distance: '50vw' });
};

export function slideInAnimation(opts?: SlideAnimationOpts): Variants {
  const { delay = 0, ease = 'circOut', distance = '100vw', direction = SlideDirection.FromLeft } =
    opts ?? {};

  // slideDistance = something like "100vw" or "20px":
  const negatedDistance = '-' + distance;

  const isVertical =
    direction === SlideDirection.FromTop || direction === SlideDirection.FromBottom;

  const verticalDistance = direction === SlideDirection.FromTop ? negatedDistance : distance;
  const horizontalDistance = direction === SlideDirection.FromLeft ? negatedDistance : distance;

  const initialPosition = isVertical ? { y: verticalDistance } : { x: horizontalDistance };

  return {
    hidden: initialPosition,
    show: { x: 0, y: 0, transition: { duration: 0.5, delay, ease } },
  };
}

export function slideOutAnimation(opts?: SlideAnimationOpts): Variants {
  const { delay = 0, ease = 'circIn', distance = '100vw', direction = SlideDirection.FromLeft } =
    opts ?? {};

  // slideDistance = something like "100vw" or "20px":
  const negatedDistance = '-' + distance;

  const isVertical =
    direction === SlideDirection.FromTop || direction === SlideDirection.FromBottom;

  const verticalDistance = direction === SlideDirection.FromTop ? negatedDistance : distance;
  const horizontalDistance = direction === SlideDirection.FromLeft ? negatedDistance : distance;

  //const initialPosition = isVertical ? { y: verticalDistance } : { x: horizontalDistance };
  const x = isVertical ? 0 : horizontalDistance;
  const y = !isVertical ? 0 : verticalDistance;

  return {
    hidden: { x:0, y:0},
    show: { x , y, transition: { duration: 0.5, delay, ease } },
  };
}
