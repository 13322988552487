import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { fadeInFromBottomAnimation, SlideDirection, slideInAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorDark, contentColorDark, highlightColor } from '../../styles';
import { Theme } from '../../types';

const BackgroundImage = styled(motion.div)<{ url: string }>`
  position: absolute;
  width: 100%;
  height: 100%;

  background-image: url('${(props) => props.url}');
  background-repeat: no-repeat;
  background-size: cover;

  filter: grayscale(100);
  opacity: 0.5;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColorDark};
  text-transform: uppercase;
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 3.83em;
  letter-spacing: -0.002em;
  margin-top: 0.016em;
`;

const DividerBar = styled(motion.div)`
  min-height: 0.278em;
  width: 14.1%;
  margin-top: 0.4em;
  background: ${contentColorDark};
`;

const Divider = styled(motion.div)`
  width: 102%;
  margin-top: -0.05em;
  margin-bottom: 0.1em;
  border: none;
  border-bottom: 1px solid ${contentColorDark};
`;

const SubTitleBlock = styled.div`
  width: max-content;
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 3.825em;
  margin-top: 0.178em;
  letter-spacing: 0.000089em;
`;

const StatementBlock = styled(motion.div)`
  text-transform: none;
  margin-top: 2.6rem;
`;

const StatementTitle = styled.div`
  color: ${highlightColor};
  font-size: 1.11em;
  line-height: 1.2em;
  margin-top: 2.75em;
  letter-spacing: 0.001em;
`;

const Statement = styled.div`
  position: relative;
  top: 0.4em;

  line-height: 1.33em;
  margin-top: 0.1em;
  margin-left: 0.11em;
  margin-bottom: 0;

  font-size: 1.01em;
  font-weight: normal;
  letter-spacing: 0.02em;
`;

const Text = styled(motion.div)`
  margin-top: -2.75%;
  margin-left: 72.5%;
  width:12em;
  font-size: 1.5em;
  letter-spacing: -0.2px;
  color: white;
`;

const Bar = styled(motion.div)`
  height: 0.8em;
  width: 2.6em;
  margin-top: 25%;
  margin-left: 65%;
  background: ${highlightColor};
  z-index:5;
`;

interface MissionStatementSlideProps {
  image: string;
  title: string;
  subtitle: string;
  text?:string;
  statementTitle?: string;
  statement?: string;
}

const MissionStatementSlide: React.FC<MissionStatementSlideProps> = ({
  image,
  title,
  subtitle,
  text,
  statementTitle,
  statement,
}) => {
  return (
    <AspectRatio style={{ background: backgroundColorDark }}>
      <BackgroundImage
        url={image}
        variants={slideInAnimation({ direction: SlideDirection.FromRight })}
        initial="hidden"
        animate="show"
      />
      <SlideTitle theme={Theme.Light}>Peter Gross Bau</SlideTitle>
      <Logo theme={Theme.Light} />
      <Slide>
        <Title variants={slideInAnimation()} initial="hidden" animate="show">
          {title}
        </Title>
        <DividerBar variants={slideInAnimation({ delay: 0.4 })} initial="hidden" animate="show" />
        <SubTitleBlock>
          <Divider variants={slideInAnimation()} initial="hidden" animate="show" />
          <SubTitle variants={slideInAnimation({ delay: 0.25 })} initial="hidden" animate="show">
            {subtitle}
          </SubTitle>
        </SubTitleBlock>
        <StatementBlock
          variants={fadeInFromBottomAnimation(1, 0.5)}
          initial="hidden"
          animate="show"
        >
          {statement?.split('\n').map((line, index) => (
            <Statement key={index}>{line}</Statement>
          ))}

          <StatementTitle>{statementTitle}</StatementTitle>
          {{text} ? (<Bar variants={
            slideInAnimation({ direction: SlideDirection.FromRight, delay: 0.4 })
          }></Bar>) : {}}
          {{text} ? (<Text
          variants={
            slideInAnimation({ direction: SlideDirection.FromRight, delay: 0.4 })
          }
          initial="hidden"
          animate="show"
        >
          { text }
        </Text>) : {}}
        </StatementBlock>
      </Slide>
    </AspectRatio>
  );
};

export default MissionStatementSlide;
