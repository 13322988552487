import React, { useCallback, useRef, useState } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import {
  DEFAULT_DROPZONE_LABEL_STRING,
  DropZoneFile,
  DropZoneProps,
  DropZoneStyles as Styles,
} from './DropZone.resources';

export default function DropZone({ onFileChange, acceptedFileTypes }: DropZoneProps) {
  const dropZoneRef = useRef<DropzoneRef>(null);
  const [label, setLabel] = useState(DEFAULT_DROPZONE_LABEL_STRING);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      // workaround, as the library unfortunately doesn't provide correct typings here
      const files: DropZoneFile[] = acceptedFiles as any[];
      const selectedFile = files[0];

      const isFileTypeCorrect =
        !acceptedFileTypes || acceptedFileTypes.split(',').some(matchesFileExtension(selectedFile));

      if (isFileTypeCorrect) {
        const lastDotIndex = selectedFile.path.lastIndexOf('.');
        const fileExt = selectedFile.path.substr(lastDotIndex);

        const fileNameWithoutExt = selectedFile.path.replace(fileExt, '');
        setLabel(fileNameWithoutExt);
        onFileChange?.(selectedFile);
      }
    },
    [onFileChange, acceptedFileTypes],
  );

  const isFileChosen = label !== DEFAULT_DROPZONE_LABEL_STRING;

  return (
    <Styles.Container>
      <Dropzone ref={dropZoneRef} onDrop={handleDrop}>
        {({ getInputProps, getRootProps }) => (
          <Styles.DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} accept={acceptedFileTypes} />
            <Styles.DropZoneLabel fileChosen={isFileChosen}>{label}</Styles.DropZoneLabel>
          </Styles.DropZoneContainer>
        )}
      </Dropzone>

      {isFileChosen && (
        <Styles.ChangeButton onClick={() => dropZoneRef.current?.open()}>
          Ändern
        </Styles.ChangeButton>
      )}
    </Styles.Container>
  );
}

function matchesFileExtension(selectedFile: DropZoneFile) {
  return (ext: string) => {
    const fileExt = ext.trim().toLowerCase();
    const filePath = selectedFile.path.toLowerCase();

    return filePath.endsWith(fileExt);
  };
}
