import React, { useEffect, useState } from 'react';
import { SlideType } from '../../types';
import { FactsSlide, FactsOutSlide } from '../slides/facts/FactsSlide';
import FoundationSlide from '../slides/foundation/FoundationSlide';
import ExpertiseSlide from '../slides/expertise/ExpertiseSlide';
import EmployeeDetailsSlide from '../slides/employee-details/EmployeeDetailsSlide'
import TraineeSlide from '../slides/trainee/TraineeSlide';
import ManagerSlide from '../slides/manager/ManagerSlide';
import IntroSlide from '../slides/intro/IntroSlide';
import MoodSlide from '../slides/mood/MoodSlide'
import MissionStatementSlide from '../slides/mission-statement/MissionStatementSlide';
import ResponsibilitySlide  from '../slides/responsibility/ResponsibilitySlide';
import UniverseSlide from '../slides/universe/UniverseSlide';
import UniverseHochbauSlide from '../slides/universeHochbau/UniverseHochbauSlide';
import UniverseHochbauExecutionSlide from '../slides/universeHochbauExecution/UniverseHochbauExecutionSlide';
import AwardsSlide from '../slides/awards/AwardsSlide';
import ReferenceDetailsSlide from '../slides/reference/ReferenceDetailsSlide';
import ReferenceSlide from '../slides/reference/ReferenceSlide';
import { SlicedFactsSlide } from '../slides/shared/sliced-pictures-and-facts/SlicedPicturesAndFacts';
import ThanksSlide from '../slides/thanks/ThanksSlide';
import TerritoriesSlide from '../slides/territories/TerritoriesSlide';
import UnderConstructionSlide from '../slides/archive/under-construction/under-construction';
import { SlideVariation } from '../types';
import PresentationSlider from './presentation-slider/PresentationSlider';
import { PresenterProps } from './Presenter.resources';

const Presenter: React.FC<PresenterProps> = ({ slides = [] }) => {
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);
  const slideComponents = slides.map(createSlideComponent);

  useEffect(requestFullscreen, []);

  useEffect(() => {
    const handleResize = () => setIsFullscreen(!!document.fullscreenElement);
    window.addEventListener('fullscreenchange', handleResize);

    return () => {
      window.removeEventListener('fullscreenchange', handleResize);
    };
  }, []);

  function requestFullscreen() {
    const documentElement: any = document.documentElement;
    let goFullscreen: () => Promise<void> = () => Promise.reject();

    if (documentElement.requestFullscreen) {
      goFullscreen = documentElement.requestFullscreen.bind(documentElement);
    } else if (documentElement.mozRequestFullScreen) {
      goFullscreen = documentElement.mozRequestFullScreen.bind(documentElement);
    } else if (documentElement.webkitRequestFullscreen) {
      goFullscreen = documentElement.webkitRequestFullscreen.bind(documentElement);
    } else if (documentElement.msRequestFullscreen) {
      goFullscreen = documentElement.msRequestFullscreen.bind(documentElement);
    } else {
      // no suitable method found
      return;
    }

    goFullscreen().then(() => setIsFullscreen(true));
  }

  return (
    <PresentationSlider
      slideComponents={slideComponents}
      isFullscreen={isFullscreen}
      onFullscreenRequest={() => requestFullscreen()}
    />
  );
};

function createSlideComponent(slide: SlideType, index: number) {
  switch (slide.type) {
    case 'reference':
      return (
        <ReferenceSlide
          key={index}
          reference={slide.reference}
          image={slide.image}
          slideVariation={index % 2 > 0 ? SlideVariation.ImageLeft : SlideVariation.ImageRight}
        />
      );

    case 'reference-details':
      return (
        <ReferenceDetailsSlide
          title={slide.title}
          description={slide.description}
          catchPhrase={slide.catchPhrase}
          largeImage={slide.largeImage}
          smallImage={slide.smallImage}
        />
      );

    case 'intro':
      return <IntroSlide key={index} />;

    case 'foundation':
      return (
        <FoundationSlide
          key={index}
          image={slide.image}
          year={slide.year}
          text={slide.text}
          generation={slide.generation}
        />
      );

    case 'expertise':
      return (
        <ExpertiseSlide
          key={index}
          image={slide.image}
          years={slide.years}
          text={slide.text}
        />
      );

      case 'trainee':
        return (
          <TraineeSlide
            key={index}
            image={slide.image}
            awardImages={slide.awardImages}
            title={slide.title}
            subTitle={slide.subTitle}
          />
        );

      case 'manager':
        return (
          <ManagerSlide
            key={index}
            {...slide}
          />
        );

    case 'mood':
      return( <MoodSlide
          name={slide.name}
          rtype={slide.rtype}
          description={slide.description}
          image={slide.image}
          slideVariation={index % 2 > 0 ? SlideVariation.ImageLeft : SlideVariation.ImageRight}
        />
        );

    case 'sliced-pictures-and-facts':
      return <SlicedFactsSlide key={index} title={slide.title} imageFacts={slide.imageFacts} />;

    case 'facts':
      return (
        <FactsSlide
          key={index}
          locations={slide.locations}
          revenue={slide.renveue}
          employees={slide.employees}
          trainees={slide.trainees}
        />
      );

    case 'facts-out':
      return (
        <FactsOutSlide
          key={index}
          locations={slide.locations}
          revenue={slide.revenue}
          constructionSites={slide.constructionSites}
          vehicles={slide.vehicles}
          title={slide.title}
          employees={slide.employees}
          projectLeads={slide.projectLeads}
          siteManagers={slide.siteManagers}
          trainees={slide.trainees}
        />
      );

    case 'mission':
      return (
        <MissionStatementSlide
          key={index}
          image={slide.image}
          title={slide.title}
          subtitle={slide.subtitle}
          text={slide.text}
          statementTitle={slide.statementTitle}
          statement={slide.statement}
        />
      );

    case 'territories':
    return(
        <TerritoriesSlide
          key={index}
          region= {slide.region}
          circle= {slide.circle}
        />
    );

    case 'universe':
    return(
      <UniverseSlide
         key={index}
         title={slide.title}
         toptitle={slide.toptitle}
         subtitle={slide.subtitle}
         images = {slide.images}
      />
    )
    
    case 'awards':
    return(
      <AwardsSlide
         key={index}
         title={slide.title}
         toptitle={slide.toptitle}
         subtitle={slide.subtitle}
         gridImages = {slide.gridImages}
      />
    )
    
    case 'universe-hochbau':
    return(
      <UniverseHochbauSlide
         key={index}
         title={slide.title}
         subtitle={slide.subtitle}
         image={slide.image}
      />
    )

    case 'universe-hochbau-execution':
    return(
      <UniverseHochbauExecutionSlide
         key={index}
         title={slide.title}
         subtitle={slide.subtitle}
         image = {slide.image}
      />
    )

    case 'responsibility':
    return (
        <ResponsibilitySlide
          key={index}
          {...slide}
        />
    );

      case 'employee-details':
      return (
        <EmployeeDetailsSlide
        title={slide.title}
        backgroundLeft={slide.backgroundLeft}
        backgroundMiddle={slide.backgroundMiddle}
        backgroundRight={slide.backgroundRight}
        employees={slide.employees}
        projectLeads={slide.projectLeads}
        siteManagers={slide.siteManagers}
        foremen={slide.foremen}
        />
      )

    case 'under-construction':
      return (
        <UnderConstructionSlide
          key={index}
          title={slide.title}
          targetSlideData={slide.targetSlideData}
        />
      );

    case 'thanks':
      return <ThanksSlide key={index} image={slide.image} contacts={slide.contacts} />;

    default:
      throw new Error(`Presenter: Unknown slide type "${slide!.type}"`);
  }
}

export default Presenter;
