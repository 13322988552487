import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import { highlightColor, slideImageStyle, titleTextStyle } from '../../styles';
import { Theme } from '../../types';

type TraineeSlideProps = {
  image: string;
  awardImages: string[],
  title: string;
  subTitle: string;
};

const Slide = styled.div`
  ${BaseSlideCss};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};
  min-width: 50%; 
  z-index: 5;
  margin: 0;
  background-position: center;
`;

const AwardImage = styled(motion.div)`
  ${slideImageStyle};
  background-size: contain; 
  background-position: center;
  min-width: 10%; 
  width: 11em;
  height: 10em;
  z-index: 50;
  margin-right: 0px;
`;

const RightSideWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 8% 5% 10% 4.5%;
`;

const TitleWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  margin: -0.8% 0 0 1.3%;
`;

const AwardImagesWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: 30px 0 45px -1.3%;
`;

const TextWrapper = styled(motion.span)` 
    font-size: 0.9em;
    font-weight: normal;
    margin-right: -1em;
    line-height: 1.3em;
    margin-left:-0.5em;
`;

const BoldText = styled(motion.span)`
  font-weight: bold;
`;

const RedBoldText = styled(motion.span)`
  color: ${highlightColor};
  font-weight: bold;
`;

const Title = styled(motion.span)`
  ${titleTextStyle}
  font-size: 2.9em;
  margin-right: 2%;
  line-height: 1;
`;

const SubTitle = styled(motion.span)`
  ${titleTextStyle}
  font-size: 2.9em;
  margin-right: 2%;
  line-height: 1;
  color: black;
  display:block;
`;

const DividerWrapper = styled(motion.div)`
  margin: 0.7em 0 0.7em 0;
  z-index: 30;
`;

const DividerBar = styled(motion.div)`
  height: 0.5em;
  width: 30%;

  background: ${highlightColor};

  z-index: 20;
`;

const Divider = styled(motion.div)`
  width: 75%;

  margin: 0;

  border: none;
  border-bottom: 1px solid ${highlightColor};

  z-index: 20;
`;

const DividerSlideDistance = '65vw';

const TraineeSlide: React.FC<TraineeSlideProps> = ({ image, awardImages, title, subTitle }) => {
  return (
    <AspectRatio>
      <SlideTitle theme={Theme.Light}>Weiterentwicklung</SlideTitle>

      <Slide>
        <Image
          url={image}
          variants={slideInHalfAnimation({ direction: SlideDirection.FromBottom })}
          initial="hidden"
          animate="show"
        />
        <RightSideWrapper
          variants={slideInHalfAnimation({ direction: SlideDirection.FromRight, delay: 0.25 })}
          initial="hidden"
          animate="show"
        >
          <TitleWrapper>
            <Title>{title}</Title>
            <DividerWrapper>
              <DividerBar
                variants={slideInAnimation({
                  direction: SlideDirection.FromRight,
                  delay: 0.5,
                  distance: DividerSlideDistance,
                })}
                initial="hidden"
                animate="show"
              />
              <Divider
                variants={slideInAnimation({
                  direction: SlideDirection.FromRight,
                  delay: 0.5,
                  distance: DividerSlideDistance,
                })}
                initial="hidden"
                animate="show"
              />
            </DividerWrapper>            
            <SubTitle>{subTitle}</SubTitle>
            <AwardImagesWrapper>
              {awardImages.map(aw =><AwardImage url={aw} />) }
          </AwardImagesWrapper>
          <TextWrapper>Durch die gründliche <BoldText>Ausbildung eigener Fachkräfte am Bau</BoldText><br /> und der Arbeit mit ca. <RedBoldText>300 hochqualifizierten Bauingenieuren</RedBoldText><br /> im eigenen Haus, wissen wir, dass wir uns auf den Qualitätsanspruch unseres Teams verlassen können.</TextWrapper>
          </TitleWrapper>
        </RightSideWrapper>

        <Logo theme={Theme.Dark} />
      </Slide>
    </AspectRatio>
  );
};

export default TraineeSlide;
