import { CommonStyles, styled } from '../../styles';
import { PresentationFile } from '../../types';
type LabelType = 'success' | 'failure' | 'standard';

export interface StartPresentationProps {
  onStartPresentation?: (file: PresentationFile) => void;
}

export const StartPresentationStyles = {
  ViewContainer: styled.div`
    ${CommonStyles.centerChildren};

    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;

    position: relative;
    width: 100vw;
    height: 100vh;
  `,
  PresentationWizard: styled.div`
    position: relative;
    min-height: 0;
    width: 660px;
    flex: 0 1 auto;

    margin: 40px;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;

    border-radius: ${(p) => p.theme.values.borderRadius};
    background: ${(p) => p.theme.colors.white};
  `,
  LoadingFeedbackContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  QuickManual: {
    Container: styled.div`
      display: flex;
      flex-direction: column;

      padding: 30px 0;
      background: ${(p) => p.theme.colors.grey[700]};
    `,
  },
  Headline: styled.h3`
    margin: 20px 0;
  `,
  Button: styled.button`
    ${CommonStyles.buttonBaseCss};

    margin: 40px 0 20px;

    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.colors.success};
  `,
};
