import React, { useEffect, useState } from 'react';
import Presentator from './components/presentator/Presentator';
import StartPresentation from './components/start-presentation/StartPresentation';
import { styled } from './styles';
import { PresentationFile } from './types';

const AppContainer = styled.div``;

function App() {
  const [presentation, setPresentation] = useState<PresentationFile>();


  useEffect(()=>{

    if (window.location.hash) {
      // hash found in url

      // remove # and fetch presentation from sever
      const uid = window.location.hash.slice(1)

      // fetching... set some loading state here?!? -> create presentations is currently very fast..
      fetch(`/p/${uid}`).then(response => response.json()).then((data)=>{

          // set presentation
          setPresentation(data)

      }).catch(()=>{
          // presentation not found or some error ...?!?

      })
    }

  }, [])


  return (
    <AppContainer>
      {presentation ? (
        <Presentator presentation={presentation} />
      ) : (
        <StartPresentation onStartPresentation={setPresentation} />
      )}
    </AppContainer>
  );
}

export default App;
