import { css } from 'styled-components';
import { contentColor, contentColorDark, highlightColor } from './colors';

export const titleTextStyle = css`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 2.75em;
  font-weight: bold;
`;

export const descriptionTextStyle = css`
  color: ${contentColor};
  font-size: 0.75em;
`;

export const catchPhraseTextStyle = css`
  color: ${contentColor};
  font-size: 0.75em;
  font-weight: bold;
`;

export const subTitleTextStyle = css`
  color: ${contentColor};
  text-transform: uppercase;
  font-size: 2.575em;
  font-weight: bold;
  letter-spacing: -0.015em;
`;

export const subSubTitleTextStyle = css`
  ${titleTextStyle}
  font-size: 1.25em;
`;

export const slideTitleTextStyle = css`
  color: ${contentColor};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
`;

export const subTitleTextStyleDark = css`
  color: ${contentColorDark};
  text-transform: uppercase;
  font-size: 2.575em;
  font-weight: bold;
  letter-spacing: -0.015em;
`;