import { PropsWithChildren } from 'react';
import { styled } from '../../../styles';

export interface KeyboardShortcutProps extends PropsWithChildren<{}> {
  icon: 'arrows' | 'esc' | 'reload' | 'fullscreen';
}

export const KeyboardShortcutStyles = {
  Container: styled.div`
    display: inline-flex;
    align-items: flex-end;
    margin: 10px 5px;
  `,
  IconContainer: styled.div`
    width: 140px;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
  `,
  Text: styled.span`
    width: 100%;
  `,
};
