import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorLight, contentColor, highlightColor } from '../../styles';
import { Theme } from '../../types';
import { css } from 'styled-components';

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColor};
  text-transform: uppercase;
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: -0.002em;
  margin-top: 0.016em;
`;

const borderSize = '0.45em';

const PinTriangleStyle = (props:{top: string, left: string}) => css`
  position: absolute;
  left: ${props.left};
  top: ${props.top};
  width: 0; 
  height: 0;
  border-right: ${borderSize} solid transparent;
  border-left: ${borderSize} solid transparent; 
  border-top: ${borderSize} solid transparent;
  border-bottom: ${borderSize} solid transparent; 
`
const PinTriangleLeft = styled(motion.div)`
  ${PinTriangleStyle}
  border-right:${borderSize} solid ${highlightColor};
`
const PinTriangleTop = styled(motion.div)`
  ${PinTriangleStyle}
  border-bottom:${borderSize} solid ${highlightColor};
`

const PinTriangleBottom = styled(motion.div)`
  ${PinTriangleStyle}
  border-top:${borderSize} solid ${highlightColor};
`

const PinDataStyle = (props:{left: string, top: string}) => css`
  position: absolute;
  left: ${props.left};
  top: ${props.top};
`

const PinData = styled(motion.div)`
    ${PinDataStyle}
`

const PinH3 = styled(motion.h3)`
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
`

const DividerLine = styled(motion.div)`
  position: absolute;
  width: 30%;
  margin: 0 0 0 0;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

const DividerBar = styled(motion.div)`
  position: relative;
  height: 0.35em;
  width: 7.8em;
  margin: 0em 0 -1px 0;
  background: ${highlightColor};
`;

const SubTitleBlock = styled.div`
  width: max-content;
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 3.0em;
  margin-top: 0.178em;
  letter-spacing: 0.000089em;
`;

const ImageStyle = (props: { left: string, width:string, top: string, src: string }) => css`
    position: absolute;
    left: ${props.left};
    top: ${props.top};
    width: ${props.width};
    height: auto;
    src: url(${props.src});
`;

const AbsoluteImage = styled(motion.img)`
    ${ImageStyle}
`

interface UniverseSlideProps {
  title: string;
  subtitle: string;
  toptitle: string;
  images: string[];
}

const UniverseSlide: React.FC<UniverseSlideProps> = ({
  title,
  subtitle,
  toptitle,
  images
}) => {
    return (
    <AspectRatio style={{ background: backgroundColorLight }}>
      <SlideTitle theme={Theme.Dark}>Peter Gross Bau - Universum</SlideTitle>
      <Logo theme={Theme.Dark} />
      <Slide>
        <Title variants={slideInAnimation()} initial="hidden" animate="show">
          {title}
        </Title>
         <SubTitleBlock>
          <DividerLine variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })} initial="hidden" animate="show">
        <DividerBar
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })}
          initial="hidden"
          animate="show"
        ></DividerBar>
      </DividerLine>
          <SubTitle variants={slideInAnimation({ delay: 0.25 })} initial="hidden" animate="show">
            {subtitle}
          </SubTitle>
        </SubTitleBlock>
        {/*hochbau*/}
        <AbsoluteImage initial="hidden" animate="show" top="0" left="21.7em" width="55%" variants={slideInAnimation({ delay: 0.5, direction: SlideDirection.FromTop })} src={images[3]}/>
        {/*tiefbau*/}
        <AbsoluteImage initial="hidden" animate="show" top="12em" left="8em" width="42%" variants={slideInAnimation({ delay: 2.5, direction: SlideDirection.FromBottom })} src={images[2]}/>
        {/*Umwelt*/}
        <AbsoluteImage initial="hidden" animate="show" top="19em" left="27em" width="28%" variants={slideInAnimation({ delay: 3.5, direction: SlideDirection.FromBottom })} src={images[1]}/>
        {/*Produktion*/}
        <AbsoluteImage initial="hidden" animate="show" top="10.5em" left="37em" width="38%" variants={slideInAnimation({ delay: 4.5, direction: SlideDirection.FromBottom })} src={images[0]}/>
        <PinTriangleBottom initial="hidden" animate="show"  top="23.5%" left="48%" variants={slideInAnimation({ delay:1, direction: SlideDirection.FromTop })}></PinTriangleBottom>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay:  1, direction: SlideDirection.FromTop })}  top="16%" left="41.5%">
            <PinH3>Hochbau</PinH3>
        </PinData>
         <PinTriangleLeft initial="hidden" animate="show"  top="13.2%" left="73.7%" variants={slideInAnimation({ delay:1.5, direction: SlideDirection.FromRight })}></PinTriangleLeft>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay: 1.5, direction: SlideDirection.FromRight })}  top="9.5%" left="76%">
            <PinH3>Technik</PinH3>
        </PinData>
         <PinTriangleLeft initial="hidden" animate="show"  top="25.65%" left="84.5%" variants={slideInAnimation({ delay:2, direction: SlideDirection.FromRight })}></PinTriangleLeft>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay: 2, direction: SlideDirection.FromRight })}  top="22%" left="87%">
            <PinH3>Services</PinH3>
        </PinData>
        <PinTriangleTop initial="hidden" animate="show"  top="77.5%" left="34%" variants={slideInAnimation({ delay:3, direction: SlideDirection.FromBottom })}></PinTriangleTop>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay: 3, direction: SlideDirection.FromBottom })}  top="77.8%" left="23%">
            <PinH3>Infrastruktur</PinH3>
        </PinData>
        <PinTriangleTop initial="hidden" animate="show"  top="83%" left="65%" variants={slideInAnimation({ delay:4, direction: SlideDirection.FromBottom })}></PinTriangleTop>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay: 4, direction: SlideDirection.FromBottom })}  top="83.3%" left="65%">
            <PinH3>Umwelt</PinH3>
        </PinData>
         <PinTriangleTop initial="hidden" animate="show"  top="68%" left="86%" variants={slideInAnimation({ delay:5, direction: SlideDirection.FromBottom })}></PinTriangleTop>
        <PinData initial="hidden" animate="show"  variants={slideInAnimation({ delay: 5, direction: SlideDirection.FromBottom })}  top="68.3%" left="86%">
            <PinH3>Produktion</PinH3>
        </PinData>
      </Slide>
    </AspectRatio>
  );
};

export default UniverseSlide;
