import styled from 'styled-components';

/**
 * Hint: https://stackoverflow.com/a/36295495
 * - Wrapper to preserve aspect ratio 16:9 based on the biggest possible width or height.
 * - Sets initial font size based on width (default size ~16px of PPTX Master). Scale accordingly: 32px ≙ 2em | 48px ≙ 2.5em ...
 * - Via media query: If too wide for aspect ratio to hold, pushes to max height and sets font size in relation to height.
 *
 * Usage:
 * <AspectRatio>
 *  <span style="font-size: 1.5em;">
 *    ratio scaled text ~24px
 *  </span>
 * </AspectRatio>
 */
export const AspectRatio = styled.div`
  width: 100vw;
  height: calc(100vw * 9 / 16);

  font-size: 1.7vw;

  /* align center */
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Hide scrollbars during animations. */
  overflow: hidden;

  @media (min-width: 177.778vh) {
    height: 100vh;
    width: calc(100vh * 16 / 9);

    font-size: calc(1.7vh * 16 / 9);
  }
`;
