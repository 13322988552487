import { css } from 'styled-components';

export const slideImageStyle = (props: { url: string }) => css`
    background-image: url("${props.url}");
    background-repeat: no-repeat;
    background-size: cover;

    z-index: 10;
`;


export const gridImageStyle = (props: { url: string, area: string }) => css`
    background-image: url("${props.url}");
    grid-area: ${props.area};
    background-repeat: no-repeat;
    background-size: contain;
`;