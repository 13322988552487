import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorDark, contentColor, highlightColor } from '../../styles';
import { Theme } from '../../types';
import { ResponsibilitySlideProps } from '../../../types/slide-type';

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColor};
  text-transform: uppercase;
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: -0.002em;
  margin-top: 0.016em;
`;

export const DividerLine = styled(motion.div)`
  position: absolute;
  width: 33.5%;
  margin: 0 0 0 0;
  border: none;
  border-bottom: 1px solid white;
`;

export const DividerBar = styled(motion.div)`
  position: relative;
  height: 0.35em;
  width: 8.8em;
  margin: 0em 0 -1px 0;
  background: white;
`;

const SubTitleBlock = styled.div`
  width: max-content;
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 3em;
  margin-top: 0.178em;
  color: white;
  letter-spacing: 0.000089em;
`;

const ValueIndexContainer = styled(motion.div)`
  width: 100%;
  background: ${backgroundColorDark};
  height: 25%;
  position: absolute;
  bottom: 25%;
  padding: 3.4em 0 0 4.3em;
  box-sizing: border-box;
  display: flex;
  font-weight: bold;
  align-items: flex-end;
  justify-content: space-between;
`;

const ValueIndex = styled(motion.div)`
  font-size: 3.325em;
  color: ${highlightColor};
  letter-spacing: 0.000089em;

  width: 20%;
  padding-right: 4%;
`;

const ValueBar = styled(motion.div)`
  height: 0.1em;
  width: 0.6em;
  background: ${highlightColor};
`;

const ValueContainer = styled.div`
  width: 100%;
  background: white;
  height: 25%;
  position: absolute;
  bottom: 0;
  border-top: 2px solid ${highlightColor};
  padding: 1.2em 0 4.3em 4.3em;
  box-sizing: border-box;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
`;

const Value = styled(motion.div)`
  font-size: 1.2em;
  color: black;
  letter-spacing: 0.000089em;
  padding-right: 4%;

  width: 20%;
`;

const ResponsibilitySlide: React.FC<ResponsibilitySlideProps> = ({
  title,
  subtitle,
  values
}) => {
  return (
    <AspectRatio style={{ background: backgroundColorDark }}>
      <SlideTitle theme={Theme.Light}>Zertifikate</SlideTitle>
      <Logo theme={Theme.Light} />
      <Slide>
        <Title variants={slideInAnimation()} initial="hidden" animate="show">
          {title}
        </Title>
         <SubTitleBlock>
          <DividerLine variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })} initial="hidden" animate="show">
        <DividerBar
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })}
          initial="hidden"
          animate="show"
        ></DividerBar>
      </DividerLine>
          <SubTitle variants={slideInAnimation({ delay: 0.25 })} initial="hidden" animate="show">
            {subtitle.split('\n').map((t,i) => <div key={i}>{t}</div>)}
          </SubTitle>
        </SubTitleBlock>
      </Slide>
      <ValueIndexContainer>
          {values.map((v, i) => 
          <ValueIndex key={i}
            variants={slideInAnimation({ delay: 1*(i+1), direction: SlideDirection.FromRight})} initial="hidden" animate="show">
            <div>{`0${i+1}.`}</div>
            {<ValueBar></ValueBar>}
          </ValueIndex>)}
      </ValueIndexContainer>
      <ValueContainer>
       {values.map((v, i) => 
       <Value key={i}
        variants={slideInAnimation({ delay: 1.1*(i+1), direction: SlideDirection.FromRight})} initial="hidden" animate="show">
         {v}
       </Value>)}
      </ValueContainer>
    </AspectRatio>
  );
};

export default ResponsibilitySlide;
