import { motion } from 'framer-motion';
import { css } from 'styled-components';
import { CommonStyles, styled } from '../../../styles';

export interface PresentationSliderProps {
  slideComponents: React.ComponentElement<any, any>[];
  isFullscreen?: boolean;
  onFullscreenRequest?: () => void;
}

export const PresentationSliderAnimations = {
  variants: {
    enter: (direction: NavigationDirection) => {
      var x = '';
      var o = 0;
      switch (direction) {
        case 'next':
          x = '100vw';
          break;
        case 'previous':
          x = '-100vw'
          break;
        case 'noAnim':
          x = '0'
          o = 1;
          break;
        default:
          break;
      }
      return {
        x: x,
        opacity: o,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: NavigationDirection) => {
      var x = '';
      var o = 0;
      switch (direction) {
        case 'next':
          x = '-100vw';
          break;
        case 'previous':
          x = '100vw'
          break;
        case 'noAnim':
          x = '0'
          o = 1;
          break;
        default:
          break;
      }
      return {
        zIndex: 0,
        x: x,
        opacity: o,
      };
    },
  },
};

export type NavigationDirection = 'previous' | 'next' | 'noAnim';

const helperElementsPositioningCss = css`
  position: absolute;
  bottom: 10px;
  height: 40px;
  padding: 0 12px;
  display: flex;

  background: white;

  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  user-select: none;

  border-radius: 30px;
  cursor: pointer;
  z-index: 100;
`;

const helperButtonCss = css`
  padding: 0 4px;
  border-radius: ${(p) => p.theme.values.borderRadius};

  &:hover {
    background: ${(p) => p.theme.colors.grey[500]};
  }
`;

export const PresentationSliderStyles = {
  Container: styled.div`
    ${CommonStyles.fullScreen};
  `,
  FullscreenButton: styled.div`
    ${helperElementsPositioningCss}
    left: 10px;
    transform: scale(-1);
  `,
  PresentationHelper: styled.div`
    ${helperElementsPositioningCss};
    right: 10px;
  `,
  HelperButton: styled.div`
    ${helperButtonCss};
  `,
  CloseButton: styled.div`
    ${helperButtonCss};
    margin-left: 4px;

    &:hover {
      background: ${(p) => p.theme.colors.warn};
    }
  `,
  SlideContainer: styled(motion.div)<{ hideMouse?: boolean }>`
    cursor: ${(p) => (p.hideMouse ? 'none' : 'default')};
  `,
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
export const swipeConfidenceThreshold = 10000;
export const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
