import { CommonStyles, styled } from '../../styles';
import { PresentationFile } from '../../types';

export interface PresentatorProps {
  presentation: PresentationFile;
}

export const PresentatorStyles = {
  Container: styled.div`
    ${CommonStyles.fullScreen};
    overflow: hidden;
  `,
};
