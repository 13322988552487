import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../../styled-components';
import { Logo } from '../../../styled-components/logo';
import { highlightColor, slideImageStyle } from '../../../styles';
import { Theme } from '../../../types';
import YellowTape from '../../../../assets/base64/yellow-tape';

type UnderConstructionSlideProps = {
  title: string;
  targetSlideData: Record<string, any>;
};

const Slide = styled.div`
  ${BaseSlideCss};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;

  font-weight: bold;
`;

const Text = styled(motion.span)`
  color: ${highlightColor};

  width: 80%;

  font-size: 4em;
  font-weight: bold;

  text-align: center;

  margin-bottom: 3%;
  margin-top: 10% !important;
`;

const ImageLeft = styled.div`
  ${slideImageStyle};

  width: 30%;
  height: 100%;

  margin-bottom: 0;
  margin-left: -10px;

  align-self: flex-start;
`;

const ImageRight = styled.div`
  ${slideImageStyle};

  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

  width: 30%;
  height: 100%;

  margin-bottom: 0;
  margin-right: -10px;

  align-self: flex-end;

  float: right;
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 30%;

  display: flex;
  flex-direction: row;

  justify-content: space-between;

  align-items: stretch;

  z-index: 4;
`;

/**
 * `UnderConstructionSlide` is only a temporary slide that should not be used in production.
 */
const UnderConstructionSlide: React.FC<UnderConstructionSlideProps> = ({
  title,
  targetSlideData,
}) => {
  console.log('Target Slide Data: ', targetSlideData);

  return (
    <AspectRatio>
      <SlideTitle>{title}</SlideTitle>

      <Slide>
        <Text>Diese Seite entsteht später</Text>

        <ImageWrapper>
          <ImageLeft url={YellowTape} />
          <ImageRight url={YellowTape} />
        </ImageWrapper>

        <Logo theme={Theme.Dark} />
      </Slide>
    </AspectRatio>
  );
};

export default UnderConstructionSlide;
