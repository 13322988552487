import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { AspectRatio } from '../../../styled-components';
import { Logo } from '../../../styled-components/logo';
import { SlicedFacts , SlicedFactsBackwards , SlicedFactsBackwardsAfter } from './sliced-facts/SlicedFacts';
import {
  backgroundColorLight,
  contentColor,
  highlightColor,
  subSubTitleTextStyle,
} from '../../../styles';
import { Theme } from '../../../types';
import { ImageFact } from '../../../types/sliced-fact';

const dividerBarHeightInPercent = 20;

const DividerBar = styled(motion.div)`
  position: absolute;
  left: 11.95%;
  bottom: 46.9%;
  height: ${dividerBarHeightInPercent}%;
  width: 0.5em;
  background: ${highlightColor};
  z-index: 40;
`;

const SliceContainer = styled.div`
  position: absolute;
  display: flex;
  width: 88.125%;
  height: 100%;
  left: 11.875%;

  overflow: hidden;
  z-index: 30;
`;

const TopTitle = styled(motion.div)`
  ${subSubTitleTextStyle}

  color: ${contentColor};
  font-size: 0.8em;
  position: absolute;
  left: 2.4%;
  top: 2.4%;
`;

interface SlicedFactsSlideProps {
  title: string;
  imageFacts: ImageFact[];
  sliceAnimationDelay?: number;
  useLightTheme?: boolean;
}

interface SlicedFactsBackwardsSlideProps {
  title: string;
  imageFacts: ImageFact[];
  imageFacts2: ImageFact[];
  sliceAnimationDelay?: number;
  useLightTheme?: boolean;
}

export function SlicedFactsSlide({
  imageFacts = [],
  title,
  sliceAnimationDelay = 0.5,
  useLightTheme
}: SlicedFactsSlideProps) {
  let delay = 0.5;

  return (
    <AspectRatio style={{ background: backgroundColorLight }}>
        <TopTitle initial="hidden" animate = "show">
          {title}
        </TopTitle>

      <DividerBar initial="hidden" animate="show" />

      <SliceContainer>
        {imageFacts.map((imageFact, index) => {
          const slide = (
            <SlicedFacts
              key={index}
              fact={imageFact}
              groupAnimationDelay={delay}
              sliceAnimationDelay={sliceAnimationDelay}
            />
          );

          // add delay after construction of component, so that the first starts right away
          delay += imageFact.facts.length * 0.5;

          return slide;
        })}
      </SliceContainer>

      <Logo theme={(useLightTheme) ? Theme.Light : Theme.Dark } />
    </AspectRatio>
  );
}

export function SlicedFactsSlideBackwards({
  imageFacts = [],
  imageFacts2 = [],
  title,
  sliceAnimationDelay = 0.5,
  useLightTheme
}: SlicedFactsBackwardsSlideProps) {
  let delay = 0.0;

  return (
    <AspectRatio style={{ background: backgroundColorLight }}>
        <TopTitle initial="hidden" animate = "show">
          {title}
        </TopTitle>

      <DividerBar initial="hidden" animate="show" />
      
      <SliceContainer>
        {imageFacts.map((imageFact, index) => {
          const slide = (
            <SlicedFactsBackwards
              key={index}
              fact={imageFact}
              groupAnimationDelay={delay}
              sliceAnimationDelay={sliceAnimationDelay}
            />
          );

          // add delay after construction of component, so that the first starts right away
          delay += imageFact.facts.length * 0.5;

          return slide;})}
      </SliceContainer>
      <SliceContainer>
        {
          imageFacts2.map((imageFact, index) => {
          const slide = (
            <SlicedFactsBackwardsAfter
              key={index}
              fact={imageFact}
              groupAnimationDelay={delay}
              sliceAnimationDelay={sliceAnimationDelay}
            />
          );

          // add delay after construction of component, so that the first starts right away
          delay += imageFact.facts.length * 0.5;

          return slide;
        })}
        </SliceContainer>

      <Logo theme={(useLightTheme) ? Theme.Light : Theme.Dark } />
    </AspectRatio>
  );
}

