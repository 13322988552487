import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import { contentColor, highlightColor, slideImageStyle } from '../../styles';
import { Theme } from '../../types';
import { ManagerSlideProps } from '../../../types/slide-type';

const Slide = styled.div`
  ${BaseSlideCss};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};
  min-width: 22.5%;
  margin: 10.5% 0 9% 2.5%;
  background-position: center;
`;

const LeftSideTextWrapper = styled(motion.div)`
  display: block;
  min-width: 25%;
  text-align: right;

  margin: 14% 0% 0 0%;
`;

const RightSideWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  
  margin: 14% 0% 20% 2.5%;
`;

const TextWrapper = styled(motion.div)`
  display: block;

  text-align: left;

  margin: -0.8% 0 0 1.3%;
`;

const AcademicTitle = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;

  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 2%;
`;

const Name = styled(motion.div)`
  color: ${contentColor};
  text-transform: uppercase;

  font-size: 1.5em;
  font-weight: bold;
`;

const Title = styled(motion.div)`
  color: ${contentColor};
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 20%;
`;

const EntryYear = styled(motion.div)`
  color: ${highlightColor};
  
  font-size: 1.7em;
  font-weight: bold;
  margin: 0 .15em;
  position: relative;
  top: -.175em;
`;

const YearsExperience = styled(motion.div)`
  color: ${highlightColor};
  
  font-size: 1.7em;
  font-weight: bold;
  margin: 0 .1em 0 0;
  position: relative;
  top: -.3rem;
  display: inline;
`;

const FlexContainer = styled(motion.div)`
  display: flex;
`;

const TextParagraph = styled(motion.div)`
  color: ${contentColor};
  font-size: 1.1em;
  font-weight: bold;

  margin-top: 1.7em;
  margin-right: 2em;
  line-height: 1.6em;
  letter-spacing: -0.01em;
`;

const TextLine = styled(motion.div)`
  color: ${contentColor};  

  font-size: 1em;
  font-weight: bold;

  line-height: 1.6em;
  letter-spacing: -0.01em;
`;

const DividerWrapper = styled(motion.div)`
  position: absolute;

  left: 37.75%;
  top: 14.2%;
  width: 100%;

  z-index: 30;
`;

const DividerBar = styled(motion.div)`
  height: 0.5em;
  width: 12.25%;

  margin: 1em 0 -1px 0;

  background: ${highlightColor};

  z-index: 20;
`;

const Divider = styled(motion.div)`
  width: 100%;

  margin: 0;

  border: none;
  border-bottom: 1px solid ${highlightColor};

  z-index: 20;
`;

const DividerSlideDistance = '65vw';

const ManagerSlide: React.FC<ManagerSlideProps> = ({ academicTitle, firstName, lastName, image, title, entryYear, yearsExperience, experienceText, specialistText }) => {
  return (
    <AspectRatio>
      <SlideTitle>Beton im Blut</SlideTitle>

      <Slide>
        <LeftSideTextWrapper 
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft, delay: 0.5 })}
          initial="hidden"
          animate="show">
          <AcademicTitle>{academicTitle}</AcademicTitle>
          <Name>{firstName}</Name>
          <Name>{lastName}</Name>
        </LeftSideTextWrapper>
        <Image
          url={image}
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })}
          initial="hidden"
          animate="show"
        />
        <RightSideWrapper
          variants={slideInHalfAnimation({ direction: SlideDirection.FromBottom})}
          initial="hidden"
          animate="show"
        >
          <TextWrapper>
            <Title variants={slideInAnimation({
                  direction: SlideDirection.FromRight,
                  delay: 0.5,
                  distance: DividerSlideDistance,
                })}
                initial="hidden"
                animate="show">{title}</Title>
            <TextParagraph>
              <FlexContainer><div>seit </div><EntryYear variants={slideInAnimation({
                  direction: SlideDirection.FromRight,
                  delay: 0.5,
                  distance: DividerSlideDistance,
                })}
                initial="hidden"
                animate="show">{entryYear}</EntryYear><div> im Unternehmen</div>
              </FlexContainer>
            </TextParagraph>
            <TextParagraph>
              {experienceText?.split('\n').map((t, i) => 
                <TextLine key={i}>{i === 0 && <div><YearsExperience variants={slideInAnimation({
                  direction: SlideDirection.FromRight,
                  delay: 0.5,
                  distance: DividerSlideDistance,
                })}>{yearsExperience}</YearsExperience>{t}</div>}
                {i !== 0 && <div>{t}</div>}
                </TextLine>)}
            </TextParagraph>
            <TextParagraph>{specialistText?.split('\n').map((t,i) => <TextLine key={i}>{t}</TextLine>)}</TextParagraph>
          </TextWrapper>
        </RightSideWrapper>

        <DividerWrapper>
          <DividerBar
            variants={slideInAnimation({
              direction: SlideDirection.FromRight,
              delay: 0,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
          <Divider
            variants={slideInAnimation({
              direction: SlideDirection.FromRight,
              delay: 0.,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
        </DividerWrapper>
        <Logo theme={Theme.Dark} />
      </Slide>
    </AspectRatio>
  );
};

export default ManagerSlide;
