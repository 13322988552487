import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { fadeInFromBottomAnimation, SlideDirection, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { LogoBottom } from '../../styled-components/logo';
import {
  contentColorDark,
  slideImageStyle,
  subTitleTextStyleDark,
  titleTextStyle,
} from '../../styles';
import { SlideVariation, Theme } from '../../types';
import Divider from '../shared/Divider/Divider';

type MoodSlideProps = {
  image: string;
  name: string;
  rtype: string;
  description: string;
  slideVariation: SlideVariation;
};

interface SlideProps {
  readonly slideVariation?: SlideVariation;
}

const Slide = styled.div<SlideProps>`
  ${BaseSlideCss};

  position: relative;

  flex-direction: ${(props) =>
    props.slideVariation === SlideVariation.ImageRight ? 'row-reverse' : 'row'};

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};

  width: 100%;
`;

const Info = styled(motion.div)<{ slideVariation: SlideVariation }>`
  color: ${contentColorDark};
  position:absolute;
  width: 50%;
  height:100%;
  background-color:rgba(0,0,0,0.4);
  top:0;
  padding-top:5%;

  & > * {
    left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '0' : '50%')};

    &:first-child {
      margin-top: 5.1%;
    }

    padding-left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '12%' : '12%')};
  }
  z-index:40;
`;

const Title = styled(motion.div)`
  ${titleTextStyle};
`;

const SubTitle = styled(motion.div)`
  ${subTitleTextStyleDark}

  margin-top: 1.5rem;
`;

const DividerWrapper = styled(motion.div)<{ slideVariation: SlideVariation }>`
& > * {
    margin-left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '-3.55em' : '-3.55em')};
  }
  width: 40%;
`;

const Type = styled(motion.div)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25em;
  color: {contentColorDark}
  margin-top: 1.37em;
  letter-spacing: -0.01em;
`;

const animateChildrenAfterEachOther = { show: { transition: { staggerChildren: 0.75 } } };

const MoodSlide: React.FC<MoodSlideProps> = ({ image, name, rtype, description, slideVariation }) => {
  return (
    <AspectRatio>
      <Slide slideVariation={slideVariation}>
        <Image
          url={image}
          variants={
            slideVariation === SlideVariation.ImageLeft
              ? slideInHalfAnimation()
              : slideInHalfAnimation({ direction: SlideDirection.FromRight })
          }
          initial="hidden"
          animate="show"
        />
        <SlideTitle theme={Theme.Light} slideVariation={slideVariation}>Referenzen</SlideTitle>
        <Info
          slideVariation={slideVariation}
          variants={animateChildrenAfterEachOther}
          initial="hidden"
          animate="show"
        >
          <Title
            variants={
              slideVariation === SlideVariation.ImageLeft
                ? slideInHalfAnimation({ direction: SlideDirection.FromRight })
                : slideInHalfAnimation()
            }
            initial="hidden"
            animate="show"
          >
            {name}
          </Title>
          <DividerWrapper slideVariation={slideVariation}>
            <Divider
              fromDirection={slideVariation === SlideVariation.ImageLeft ? 'right' : 'left'}
              delay={0.25}
            ></Divider>
          </DividerWrapper>
          <SubTitle
            variants={
              slideVariation === SlideVariation.ImageLeft
                ? slideInHalfAnimation({ direction: SlideDirection.FromRight })
                : slideInHalfAnimation()
            }
            initial="hidden"
            animate="show"
          >
            {description}
          </SubTitle>
          <Type
            variants={fadeInFromBottomAnimation()}
            initial="hidden"
            animate="show"
          >
            {rtype}
          </Type>
        </Info>
        <LogoBottom theme={Theme.Light} />
      </Slide>
    </AspectRatio>
  );
};

export default MoodSlide;