import styled from 'styled-components';
import logoDark from '../../assets/logo/PG_Logo_dark.svg';
import logoLight from '../../assets/logo/PG_Logo_light.svg';
import { Theme } from '../types';

interface LogoProps {
  theme: Theme;
}

const LOGO_WITH = 6.1;
const LOGO_RIGHT_SPACE = 0.7;

export const LOGO_RIGHT_SIDE_SPACING = `${LOGO_WITH + LOGO_RIGHT_SPACE}em`;

export const Logo = styled.div<LogoProps>`
  height: 2.4em;
  width: ${LOGO_WITH}em;

  background-image: url('${(props) => (props.theme === Theme.Light ? logoLight : logoDark)}');
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 40;

  position: absolute;
  right: ${LOGO_RIGHT_SPACE}em;
  top: 0.82em;
`;

export const LogoBottom = styled.div<LogoProps>`
  height: 2.4em;
  width: ${LOGO_WITH}em;

  background-image: url('${(props) => (props.theme === Theme.Light ? logoLight : logoDark)}');
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 40;

  position: absolute;
  right: ${LOGO_RIGHT_SPACE}em;
  bottom: 0.82em;
`;