import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import introVideo from '../../../assets/intro/intro.mp4';
import previewImage from '../../../assets/intro/preview.png';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio } from '../../styled-components/layout';
import { Logo } from '../../styled-components/logo';
import { highlightColor, titleTextStyle } from '../../styles';
import { Theme } from '../../types';

type IntroSlideProps = {
  print?: boolean;
};

const IntroVideo = styled.video`
  height: 100%;
  width: 100%;
`;

const IntroImage = styled.img`
  height: 100%;
  width: 100%;
`;

const TextOverlay = styled(motion.div)`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  font-weight: bold;

  & > * {
    margin-left: 4.9%;
    margin-top: 1.7%;
  }

  & > :first-child {
    margin-top: 7.2%;
    margin-bottom: -1.1%;
  }
`;

const Title = styled(motion.div)`
  ${titleTextStyle}
  font-size: 3.8em;
`;

const DividerBar = styled(motion.div)`
  height: 0.25em;
  width: 12%;
  background: white;
`;

const Divider = styled(motion.div)`
  height: 1px;
  width: 35%;
  background: white;
  margin-top: 0;
`;

const SubTitle = styled(motion.div)`
  color: white;
  font-size: 3.78em;
  margin-top: 1.3%;
`;

const Text = styled(motion.div)`
  margin-top: 20.2%;
  margin-left: 5.3%;
  font-size: 1.5em;
  letter-spacing: -0.2px;
  color: white;
`;

const Accent = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1.4%;
  background-color: ${highlightColor};
`;

const accentAnimation = {
  hidden: { y: '5em' },
  show: { y: 0, transition: { delay: 4, duration: 0.5, ease: 'circOut' } },
};

const IntroSlide: React.FC<IntroSlideProps> = ({ print = false }) => {
  return (
    <AspectRatio>
      {print ? (
        <IntroImage src={previewImage} />
      ) : (
        // Hint: mf: chrome does not autoplay videos that are not also muted
        <IntroVideo loop autoPlay muted src={introVideo} />
      )}
      <TextOverlay>
        <Title
          variants={print ? {} : slideInHalfAnimation({ delay: 1.5 })}
          initial="hidden"
          animate="show"
        >
          WIR BAUEN
        </Title>
        <DividerBar
          variants={print ? {} : slideInHalfAnimation({ delay: 1.9 })}
          initial="hidden"
          animate="show"
        />
        <Divider
          variants={print ? {} : slideInHalfAnimation({ delay: 1.7 })}
          initial="hidden"
          animate="show"
        />
        <SubTitle
          variants={print ? {} : slideInHalfAnimation({ delay: 2.1 })}
          initial="hidden"
          animate="show"
        >
          ZUKUNFT
        </SubTitle>
        <Text
          variants={
            print ? {} : slideInAnimation({ direction: SlideDirection.FromRight, delay: 2.8 })
          }
          initial="hidden"
          animate="show"
        >
          Wir sind Peter Gross Bau
        </Text>
      </TextOverlay>
      <Accent variants={print ? {} : accentAnimation} initial="hidden" animate="show" />
      <Logo theme={Theme.Light} />
    </AspectRatio>
  );
};

export default IntroSlide;
