import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss , SlideTitle } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorLight, contentColor, highlightColor } from '../../styles';
import { Theme } from '../../types';
import { css } from 'styled-components';

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColor};
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: -0.002em;
  margin-top: 0.016em;
`;

const DividerLine = styled(motion.div)`
  position: absolute;
  width: 30%;
  margin: 0 0 0 0;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

const DividerBar = styled(motion.div)`
  position: relative;
  height: 0.35em;
  width: 7.8em;
  margin: 0em 0 -1px 0;
  background: ${highlightColor};
`;

const SubTitleBlock = styled.div`
  width: max-content;
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 3.0em;
  margin-top: 0.178em;
  letter-spacing: 0.000089em;
`;

const ImageStyle = (props: { left: string, width:string, top: string, src: string }) => css`
    position: absolute;
    left: ${props.left};
    top: ${props.top};
    width: ${props.width};
    height: auto;
    src: url(${props.src});
`;

const AbsoluteImage = styled(motion.img)`
    ${ImageStyle}
`
const TextContainer = styled(motion.div)`
  height: 80%;
  width: 25%;
  margin-top: 5%;
  margin-left: 0;
`

const Bar = styled(motion.div)`
  height: 1em;
  width: 3.7em;
  background: ${highlightColor};
  z-index:5;
`;

const Text = styled(motion.div)`
  margin-top:3%;
  font-size: 1.1em;
  color: black;
  letter-spacing: 0.00006em;
  line-height: 1.9em;
`;

interface UniverseHochbauSlideProps {
  title: string;
  subtitle: string;
  image: string;
}

const UniverseHochbauSlide: React.FC<UniverseHochbauSlideProps> = ({
  title,
  subtitle,
  image
}) => {
  return (
    <AspectRatio style={{ background: backgroundColorLight }}>
      <Logo theme={Theme.Dark} />
      <Slide>
        <SlideTitle theme={Theme.Dark}>Hochbau</SlideTitle>
        <Title variants={slideInAnimation()} initial="hidden" animate="show">
          {title}
        </Title>
         <SubTitleBlock>
          <DividerLine variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })} initial="hidden" animate="show">
        <DividerBar
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })}
          initial="hidden"
          animate="show"
        ></DividerBar>
      </DividerLine>
          <SubTitle variants={slideInAnimation({ delay: 0.25 })} initial="hidden" animate="show">
            {subtitle}
          </SubTitle>
        </SubTitleBlock>
        {/*Hochbau*/}
        <AbsoluteImage initial="hidden" animate="show"  variants={slideInAnimation({ delay: 1, direction: SlideDirection.FromBottom })} src={image} top="3em" left="19em" width="70%"/>
        <TextContainer>
          <Bar initial="hidden" animate="show"  variants={slideInAnimation({ delay: 1.5, direction: SlideDirection.FromBottom })}/>
          <Text initial="hidden" animate="show"  variants={slideInAnimation({ delay: 2, direction: SlideDirection.FromBottom })}> 
            Schlüsselfertigbau<br/>
            Rohbau <br />
            Fertigteilbau <br/>
            Planen und Bauen <br/>
            Projektentwicklung 
        </Text>
        </TextContainer>
      </Slide>
    </AspectRatio>
  );
};

export default UniverseHochbauSlide;
