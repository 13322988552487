import React from 'react';
import { ReactComponent as ArrowKeys } from '../../../assets/visuals/arrow-keys.svg';
import { ReactComponent as EscKey } from '../../../assets/visuals/esc-key.svg';
import { ReactComponent as FullScreen } from '../../../assets/visuals/fullscreen-icon.svg';
import { ReactComponent as ReloadKey } from '../../../assets/visuals/reload-key.svg';
import {
  KeyboardShortcutProps,
  KeyboardShortcutStyles as Styles,
} from './KeyboardShortcut.resources';

export default function KeyboardShortcut({ icon, children }: KeyboardShortcutProps) {
  let Icon = ArrowKeys;

  if (icon === 'esc') {
    Icon = EscKey;
  }
  if (icon === 'reload') {
    Icon = ReloadKey;
  }
  if (icon === 'fullscreen') {
    Icon = FullScreen;
  }

  return (
    <Styles.Container>
      <Styles.IconContainer>
        <Icon />
      </Styles.IconContainer>
      <Styles.Text>{children}</Styles.Text>
    </Styles.Container>
  );
}
