import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { SlideDirection, slideInAnimation , slideOutAnimation} from '../../../../animations';
import { ImageFact} from '../../../../types/sliced-fact';
import SlicedFact, {SlicedFactBackwards, SlicedFactNoLine} from '../sliced-fact/SlicedFact';

const factImageHeightInPercent = 53.1;

const Container = styled.div<{
  slotSpan: number;
}>`
  position: relative;
  height: 100%;

  overflow: hidden;
  flex: ${(p) => p.slotSpan} 1 auto;
`;

const FactContainer = styled.div`
  position: absolute;
  display: flex;

  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const PartialBackgroundImage = styled(motion.div)<{
  url: string;
  heightInPercent: number;
}>`
  width: 100%;
  height: ${(props) => props.heightInPercent}%;

  background-image: url("${(props) => props.url}");
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 30;
  background-position: center;
`;

export interface SlicedFactsProps {
  fact: ImageFact;
  groupAnimationDelay: number;
  sliceAnimationDelay?: number;
}

export interface SlicedFactsBackwardsProps {
  fact: ImageFact;
  groupAnimationDelay: number;
  sliceAnimationDelay?: number;
}

export function SlicedFacts({
  fact,
  groupAnimationDelay: animationDelay,
  sliceAnimationDelay = 0.5,
}: SlicedFactsProps) {
  let delay = animationDelay;

  return (
    <Container slotSpan={fact.facts.length ?? 1}>
      <PartialBackgroundImage
        url={fact.imageUrl}
        heightInPercent={factImageHeightInPercent}
        variants={slideInAnimation({ direction: SlideDirection.FromTop, delay: animationDelay })}
        initial="hidden"
        animate="show"
      />

      <FactContainer>
        {fact.facts.map((sliceFact, index) => {
          delay += sliceAnimationDelay;

          return <SlicedFact key={index} fact={sliceFact} animationDelay={delay} />;
        })}
      </FactContainer>
    </Container>
  );
}

export function SlicedFactsBackwards({
  fact,
  groupAnimationDelay: animationDelay,
  sliceAnimationDelay = 0.5,
}: SlicedFactsBackwardsProps) {
  let delay = animationDelay;

  return (
    <Container slotSpan={fact.facts.length ?? 1}>
      <PartialBackgroundImage
        url={fact.imageUrl}
        heightInPercent={factImageHeightInPercent}
        variants={slideOutAnimation({ direction: SlideDirection.FromTop, delay: animationDelay })}
        initial="hidden"
        animate="show"
      />

      <FactContainer>
        {fact.facts.map((sliceFact, index) => {
          delay += sliceAnimationDelay;

          return <SlicedFactBackwards key={index} fact={sliceFact} animationDelay={delay} />;
        })}
      </FactContainer>
    </Container>
  );
}

export function SlicedFactsBackwardsAfter({
  fact,
  groupAnimationDelay: animationDelay,
  sliceAnimationDelay = 0.5,
}: SlicedFactsBackwardsProps) {
  let delay = animationDelay;

  return (
    <Container slotSpan={fact.facts.length ?? 1}>
      <PartialBackgroundImage
        url={fact.imageUrl}
        heightInPercent={factImageHeightInPercent}
        variants={slideInAnimation({ direction: SlideDirection.FromTop, delay: animationDelay })}
        initial="hidden"
        animate="show"
      />

      <FactContainer>
        {fact.facts.map((sliceFact, index) => {
          delay += sliceAnimationDelay;

          return <SlicedFactNoLine key={index} fact={sliceFact} animationDelay={delay} />;
        })}
      </FactContainer>
    </Container>
  );
}
