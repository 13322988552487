import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import { contentColor, highlightColor, slideImageStyle, titleTextStyle } from '../../styles';
import { Theme } from '../../types';

type FoundationSlideProps = {
  image: string;
  text: string;
  year: number;
  generation: number;
};

const Slide = styled.div`
  ${BaseSlideCss};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};
  min-width: 40%;

  margin: 8.7% 0 0 7.2%;
`;

const RightSideWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;

  margin: 20% 10% 20% 2.5%;
`;

const TextWrapper = styled(motion.div)`
  display: block;

  text-align: left;

  margin: -0.8% 0 0 1.3%;
`;

const Text = styled(motion.span)`
  color: ${contentColor};

  font-size: 1.5em;
  font-weight: bold;

  margin-right: 4em;
  line-height: 1.6em;
  letter-spacing: -0.01em;
`;

const Year = styled(motion.span)`
  ${titleTextStyle}
  font-size: 4.9em;
  margin-right: 2%;
  line-height: 1;
`;

const Generation = styled(motion.div)`
  ${titleTextStyle}
  position: absolute;

  font-size: 5.6em;
  margin: 21.8% 0 0 85.2%;
`;

const DividerWrapper = styled(motion.div)`
  position: absolute;

  left: 41.9%;
  bottom: 30.4%;
  width: 53.6%;

  z-index: 30;
`;

const DividerBar = styled(motion.div)`
  height: 0.5em;
  width: 30%;

  margin: 1em 0 -1px 0;

  background: ${highlightColor};

  z-index: 20;
`;

const Divider = styled(motion.div)`
  width: 100%;

  margin: 0;

  border: none;
  border-bottom: 1px solid ${highlightColor};

  z-index: 20;
`;

const DividerSlideDistance = '65vw';

const FoundationSlide: React.FC<FoundationSlideProps> = ({ image, text, year, generation }) => {
  return (
    <AspectRatio>
      <SlideTitle>Gründung</SlideTitle>

      <Slide>
        <Image
          url={image}
          variants={slideInHalfAnimation({ direction: SlideDirection.FromBottom })}
          initial="hidden"
          animate="show"
        />
        <RightSideWrapper
          variants={slideInHalfAnimation({ direction: SlideDirection.FromRight, delay: 0.25 })}
          initial="hidden"
          animate="show"
        >
          <TextWrapper>
            <Year>{year}</Year>
            <Text>{text}</Text>
          </TextWrapper>
        </RightSideWrapper>
        <Generation
          variants={slideInHalfAnimation({ direction: SlideDirection.FromRight, delay: 0.25 })}
          initial="hidden"
          animate="show"
        >
          {' '}
          {generation}.{' '}
        </Generation>

        <DividerWrapper>
          <DividerBar
            variants={slideInAnimation({
              direction: SlideDirection.FromRight,
              delay: 0.5,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
          <Divider
            variants={slideInAnimation({
              direction: SlideDirection.FromRight,
              delay: 0.5,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
        </DividerWrapper>
        <Logo theme={Theme.Dark} />
      </Slide>
    </AspectRatio>
  );
};

export default FoundationSlide;
