import React from 'react';
import { DividerProps, DividerLine, DividerBar } from './Divider.resources';
import { SlideDirection, slideInHalfAnimation } from '../../../animations/index';

export default function Divider({ fromDirection, delay }: DividerProps) {
  const direction = fromDirection === 'right' ? SlideDirection.FromRight : SlideDirection.FromLeft;
  return (
    <div>
      <DividerLine variants={slideInHalfAnimation({ direction })} initial="hidden" animate="show">
        <DividerBar
          variants={slideInHalfAnimation({ direction, delay })}
          initial="hidden"
          animate="show"
        ></DividerBar>
      </DividerLine>
    </div>
  );
}
