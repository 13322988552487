// Theme.tsx
import baseStyled, { css, ThemedStyledInterface } from 'styled-components';

export const theme = {
  colors: {
    black: '#000',
    grey: {
      500: '#ccc',
      700: '#f0f0f0',
      800: '#fafafa',
    },
    blue: {
      600: '#b8c8f4',
    },
    success: '#59c28c',
    failure: '#d44d44',
    warn: '#ffa99c',
    white: '#fff',
  },
  values: {
    borderRadius: '4px',
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const CommonStyles = {
  centerChildren: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  fullScreen: css`
    height: 100vh;
    max-height: 100vh;

    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  buttonBaseCss: css`
    padding: 10px;
    font-size: 18px;
    font-weight: bold;

    outline: none;
    appearance: none;
    border: none;
    cursor: pointer;

    border-radius: ${(p) => p.theme.values.borderRadius};
  `,
};
