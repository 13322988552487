import styled, { css } from 'styled-components';
import { contentColor, contentColorDark, slideTitleTextStyle } from '../styles';
import { Theme , SlideVariation} from '../types';

interface SlideTitleProps {
  theme: Theme;
  slideVariation?: SlideVariation
}

export const SlideTitle = styled.div<SlideTitleProps>`
  ${slideTitleTextStyle}

  color: ${(props) => (props.theme === Theme.Light ? contentColorDark : contentColor)};
  position: absolute;
  left: ${(props) => (props.slideVariation === SlideVariation.ImageRight ? '66.46em' : '1.46em')};
  top: 1.3em;
  letter-spacing: 0.2px;
  font-size: 0.793em;
  z-index: 45;
`;

export const BaseSlideCss = css`
  background: white;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: 'row';
`;
