import { motion } from 'framer-motion';
import React from 'react';
import styled, {keyframes} from 'styled-components';
import { slideInAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorDark, contentColorDark, highlightColor } from '../../styles';
import { Theme } from '../../types';
import TerritoriesMap from '../../../assets/base64/territories-map';
import { css } from 'styled-components';

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColorDark};
  text-transform: uppercase;
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const AreaContainer = styled(motion.div)`
  width: 35%;
  margin-top: 2em;
  margin-left: 1.8em;
`

const Area = styled(motion.div)`
  color: ${contentColorDark};
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: -0.002em;
  margin-bottom: 1em;
  width: max-content;
  font-weight: bold;
`;

const CircleArgs = (props: {m: string}) => css`
  margin: ${props.m};
`

const Circle = styled(motion.div)`
  ${CircleArgs}
  width: 0.48em; 
  height: 0.48em;
  border-radius: 50%;
  background-color: ${highlightColor};
`

const kf = keyframes`
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
`


const AnimatedCircle = styled(motion.div)`
  ${CircleArgs}
  & {
    position: relative;
    transform: translate(-505, -50%);
    width:  0.48em;
    height:  0.48em;
    background: ${highlightColor};
    border: 0.1em solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.2em transparent;
  }
  &:before {
    content: '';
    position: absolute;
    left: -0.5em;
    top: -0.5em;
    right: -0.5em;
    bottom: -0.5em;
    border: 0.15em solid ${highlightColor};
    border-radius: 50%;
    animation: ${kf} 2s linear infinite
  }
`

const MapText = styled(motion.div)`
  font-size: 0.6em;
  margin-top:-0.2em;
  color: ${contentColorDark};
  text-transform: uppercase;
`

const MapLongText = styled(motion.div)`
  font-size: 0.6em;
  margin-top:-1.4em;
  width: 6em;
  color: ${contentColorDark};
  text-transform: uppercase;
`

const MapTextHighlight = styled(motion.div)`
  font-size: 0.6em;
  margin-top:-0.2em;
  color: ${highlightColor};
  text-transform: uppercase;
`

const DividerBar = styled(motion.div)`
  min-height: 0.15em;
  width: 30%;
  min-width:2em;
  margin-top: 0.1em;
  background: ${highlightColor};
`;

const Divider = styled(motion.div)`
  width: 102%;
  margin-top: -0.03em;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

const mapStyle = (props: {src: string}) => css`
  src: url(${props.src});
  position: absolute;
  right: 8em;
  height:91%;
  top: 1em;
`

const Map = styled(motion.img)`
  ${mapStyle};
`

const divStyle = (props: {left: string; top: string;}) => css`
  position: absolute;
  top: ${ props.top };
  left: ${ props.left };
  display:flex;
  `
const divStyleDown = (props: {left: string; top: string;}) => css`
  position: absolute;
  top: ${ props.top };
  left: ${ props.left };
  `
  
const MapPointDivDown = styled(motion.div)`
  ${divStyleDown};
`

const MapPointDiv = styled(motion.div)`
  ${divStyle};
`

interface MapPointProps { 
  name: string;
  left: string;
  top: string;
  textStyle: string;
  circleAnimation: string;
}

function MapPoint({name, left, top, textStyle, circleAnimation}: MapPointProps) {
  switch (textStyle){
    case "left":
      if(circleAnimation === "true") {
        return (
          <MapPointDiv left={left} top={top}>
            <AnimatedCircle m = "0 0.25em 0 0.25em"/>
            <MapText>{ name }</MapText>  
          </MapPointDiv>
        );
      }
      else return (
        <MapPointDiv left={left} top={top}>
            <Circle m = "0 0.25em 0 0.25em"/>
            <MapText>{ name }</MapText>  
          </MapPointDiv>
      );
      case "left-long":
      if(circleAnimation === "true") {
        return (
          <MapPointDiv left={left} top={top}>
            <AnimatedCircle m = "0 0.25em 0 0.25em"/>
            <MapLongText>{ name }</MapLongText>  
          </MapPointDiv>
        );
      }
      else return (
        <MapPointDiv left={left} top={top}>
            <Circle m = "0 0.25em 0 0.25em"/>
            <MapLongText>{ name }</MapLongText>  
          </MapPointDiv>
      );
    case "right-space":
      if(circleAnimation === "true") {
        return (
          <MapPointDiv left={left} top={top}>
            <MapText>{ name }</MapText>  
            <AnimatedCircle m = "0 0.25em 0 1em"/>
          </MapPointDiv>
        );
      }
      else return (
          <MapPointDiv left={left} top={top}>
            <MapText>{ name }</MapText>  
            <Circle m = "0 0.25em 0 1em"/>
          </MapPointDiv>
        );
        case "right":
      if(circleAnimation === "true") {
        return (
          <MapPointDiv left={left} top={top}>
            <MapText>{ name }</MapText>  
            <AnimatedCircle m = "0 0.25em 0 0.3em"/>
          </MapPointDiv>
        );
      }
      else return (
          <MapPointDiv left={left} top={top}>
            <MapText>{ name }</MapText>  
            <Circle m = "0 0.25em 0 0.3em"/>
          </MapPointDiv>
        );
    case "right-red":
      if(circleAnimation === "true") {
        return (
          <MapPointDiv left={left} top={top}>
            <MapTextHighlight>{ name }</MapTextHighlight>  
            <AnimatedCircle m = "0 0.25em 0 0.9em"/>
          </MapPointDiv>
        );
      }
      else return (
          <MapPointDiv left={left} top={top}>
            <MapTextHighlight>{ name }</MapTextHighlight>  
            <Circle m = "0 0.25em 0 0.9em"/>
          </MapPointDiv>
        );
    default:
      if(circleAnimation === "true") {
        return (
          <MapPointDivDown left={left} top={top}>
            <MapText>{ name }</MapText>  
            <AnimatedCircle m = "0.2em 0.25em 0 0"/>
          </MapPointDivDown>
        );
      }
       else return (
          <MapPointDivDown left={left} top={top}>
            <MapText>{ name }</MapText>  
            <Circle m = "0.2em 0.25em 0 0"/>
          </MapPointDivDown>
        );
  }


}

interface AreaProps {
  name: string;
  delayTime: number;
}

function AreaDiv({ name, delayTime }: AreaProps) {
  return (
        <Area variants={slideInAnimation({ delay: delayTime })} initial="hidden" animate="show">
            { name }
            <DividerBar variants={slideInAnimation({ delay: delayTime })} initial="hidden" animate="show" />
            <Divider variants={slideInAnimation()} initial="hidden" animate="show" />
        </Area>
  );
}


interface TerritoriesSlideProps {
  region: string[];
  circle?: string;
}

const TerritoriesSlide: React.FC<TerritoriesSlideProps> = ({
  region,
  circle
}) => {
  var niederlassungen = [
    ["55%","57.45%","Koblenz","left"], 
    ["57.5%","61%","Frankfurt","left"],
    ["62.4%","61.5%","Mannheim","left"],
    ["64.7%","57.3%","Pirmasens","left"],
    ["68.8%","59.95%","Karlsruhe","left"],
    ["71.9%","62.7%","Stuttgart","left"],
    ["78.88%","62.2%","Pfullendorf","left"],
    ["73.7%","75.2%","München","bottom"],
    ["31%","65.5%","Langenhagen","left"],
    ["76.5%","46.5%","Villingen-Schwenningen","right"],
    ["84%","56.75%","Basel","left"],
    ["63.6%","45.2%","St. Ingbert (HQ)","right-red"],
    ["59%","44.3%","Luxemburg","right-space"],
    ["60%","57.8%","Kaiserslautern","bottom"]
  ];
  var time = 0.2;
  var slide = <AspectRatio style={{ background: backgroundColorDark }}>
      <SlideTitle theme={Theme.Light}>Baugebiete und Niederlassungen</SlideTitle>
      <Logo theme={Theme.Light} />
      <Slide>
          <AreaContainer>
          { region.map((area) => {
            var div = <AreaDiv name={area} delayTime={time}></AreaDiv>
            time += 0.2;
            return div;
          })}
        </AreaContainer>
        <Map src={ TerritoriesMap} ></Map>
        { niederlassungen.map((niederlassung, key) => {
          var point = <MapPoint top= {niederlassung[0]} left={niederlassung[1]} name={niederlassung[2]} textStyle={niederlassung[3]} circleAnimation="false"/>;
          if (circle != null) {
              circle.split(",").forEach(element => {
                if(element.length > 2) {
                  if(niederlassung[2].toLowerCase().substring(0,3) === element.replace(/\s/g,'').toLowerCase().substring(0,3)) {
                    point = <MapPoint top= {niederlassung[0]} left={niederlassung[1]} name={niederlassung[2]} textStyle={niederlassung[3]} circleAnimation="true"/>;
                  }
                }
            });
          }
          return point;
        })}
      </Slide>
    </AspectRatio>;
  return slide;
};

export default TerritoriesSlide;