import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import { contentColor, highlightColor, slideImageStyle, titleTextStyle } from '../../styles';
import { Theme } from '../../types';

type ExpertiseSlideProps = {
  image: string;
  text: string;
  years: number;
};

const Slide = styled.div`
  ${BaseSlideCss};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};
  min-width: 50%;  
  margin: 0;
  background-position: center;
`;

const LeftSideWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;

  margin: 20% 10% 20% 5%;
`;

const TextWrapper = styled(motion.div)`
  display: block;

  text-align: left;

  margin: 9% 0 0 1.3%;
`;

const Text = styled(motion.span)`
  color: ${contentColor};

  font-size: 1.5em;
  font-weight: bold;

  line-height: 1.6em;
  letter-spacing: -0.01em;
`;

const Years = styled(motion.span)`
  ${titleTextStyle}
  font-size: 4.9em;
  margin-right: 2%;
  line-height: 1;
`;

const DividerWrapper = styled(motion.div)`
  position: absolute;

  left: 4%;
  bottom: 30.4%;
  width: 53.6%;

  z-index: 30;
`;

const DividerBar = styled(motion.div)`
  height: 0.5em;
  width: 30%;
  position: absolute;
  right: 0;
  bottom: 0;

  background: ${highlightColor};

  z-index: 20;
`;

const Divider = styled(motion.div)`
  width: 100%;

  margin: 0;

  border: none;
  border-bottom: 1px solid ${highlightColor};

  z-index: 20;
`;

const DividerSlideDistance = '50vw';

const ExpertiseSlide: React.FC<ExpertiseSlideProps> = ({ image, text, years }) => {
  return (
    <AspectRatio>
      <SlideTitle>Expertise</SlideTitle>

      <Slide>
        <LeftSideWrapper
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft, delay: 0.25 })}
          initial="hidden"
          animate="show"
        >
          <TextWrapper>
            <Years>{years}</Years>
            <Text>{text}</Text>
          </TextWrapper>
        </LeftSideWrapper>        
        <Image
          url={image}
          variants={slideInHalfAnimation({ direction: SlideDirection.FromRight })}
          initial="hidden"
          animate="show"
        />

        <DividerWrapper>
          <DividerBar
            variants={slideInAnimation({
              direction: SlideDirection.FromLeft,
              delay: 0.5,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
          <Divider
            variants={slideInAnimation({
              direction: SlideDirection.FromLeft,
              delay: 0.5,
              distance: DividerSlideDistance,
            })}
            initial="hidden"
            animate="show"
          />
        </DividerWrapper>
        <Logo theme={Theme.Light} />
      </Slide>
    </AspectRatio>
  );
};

export default ExpertiseSlide;
