import { CommonStyles, styled } from '../../../styles';

export const DEFAULT_DROPZONE_LABEL_STRING =
  'Klicken Sie oder ziehen Sie die Präsentationdatei hierher.';

export interface DropZoneProps {
  onFileChange?: (file?: DropZoneFile) => void;
  /**
   * Comma-separated list of file extensions being accpeted by the drop zone
   *
   * Example: `".json,.png"`
   *
   * If left out, all files are allowed.
   */
  acceptedFileTypes?: string;
}

export interface DropZoneFile {
  path: string;
  size: number;
}

export const DropZoneStyles = {
  Container: styled.div``,
  DropZoneContainer: styled.div`
    ${CommonStyles.centerChildren};

    padding: 20px;
    cursor: pointer;
    outline: none;

    border-radius: ${(p) => p.theme.values.borderRadius};
    border: 1px dashed ${(p) => p.theme.colors.grey[500]};
    background: ${(p) => p.theme.colors.grey[700]};

    &:hover {
      background: ${(p) => p.theme.colors.grey[700]};
    }
  `,
  DropZoneLabel: styled.span<{ fileChosen: boolean }>`
    color: ${(p) => (p.fileChosen ? p.theme.colors.success : p.theme.colors.black)};
  `,
  ChangeButton: styled.button`
    ${CommonStyles.buttonBaseCss};

    font-size: 14px;
    margin: 10px 0;
  `,
};
