import React from 'react';
import FactsSlideBackgroundBase64 from '../../../assets/base64/facts-slide-background';
import backgroundMiddle from '../../../assets/base64/employees-background-center';
import backGroundLeftImage from '../../../assets/base64/employees-background-left';
import AzubisBackgroundLeft from '../../../assets/base64/trainee-background-left';
import { ImageFact} from '../../types/sliced-fact';
import { SlicedFactsSlide , SlicedFactsSlideBackwards } from '../shared/sliced-pictures-and-facts/SlicedPicturesAndFacts';

interface FactsSlideProps {
  locations: number;
  revenue: number;
  employees: number;
  trainees: number;
}
interface FactsOutSlideProps {
  locations: number;
  revenue: number;
  constructionSites: number;
  vehicles: number;
  title: string;
  employees: number;
  projectLeads: number; // Projektleitende
  siteManagers: number; // Bauleiter
  trainees: number; // Poliere
}


export const FactsSlide: React.FC<FactsSlideProps> = ({ locations, revenue, employees, trainees}) => {
  const facts: ImageFact[] = [
    {
      imageUrl: FactsSlideBackgroundBase64,
      facts: [
        {
          description: 'Standorte',
          value: locations,
        },
        {
          description: 'Mio. Euro Leistung',
          value: revenue,
        },
        {
          description: 'Laufende Baustellen',
          value: employees,
        },
        {
          description: 'Baugeräte & Fahrzeuge',
          value: trainees,
        },
      ],
    },
  ];

  return <SlicedFactsSlide title="Fakten" imageFacts={facts} useLightTheme={true} />;
};

export const FactsOutSlide: React.FC<FactsOutSlideProps> = ({ locations, revenue, constructionSites, vehicles ,
  title,
  employees,
  projectLeads,
  siteManagers,
  trainees
}) => {
  const facts: ImageFact[] = [
    {
      imageUrl: backGroundLeftImage,
      facts: [
        {
          description: 'Mitarbeitende',
          value: employees,
        },
      ],
    },
    {
      imageUrl: backgroundMiddle,
      facts: [
        {
          description: 'Bauleitende',
          value: projectLeads,
        },
        {
          description: 'Facharbeitende',
          value: siteManagers,
        },
      ],
    },
    {
      imageUrl: AzubisBackgroundLeft,
      facts: [
        {
          description: 'Auszubildende',
          value: trainees,
        },
      ],
    },
  ];
  const facts2: ImageFact[] = [
    {
      imageUrl: FactsSlideBackgroundBase64,
      facts: [
        {
          description: 'Standorte',
          value: locations,
        },
        {
          description: 'Mio. Euro Leistung',
          value: revenue,
        },
        {
          description: 'Laufende Baustellen',
          value: constructionSites,
        },
        {
          description: 'Baugeräte & Fahrzeuge',
          value: vehicles,
        },
      ],
    },
  ];

  /*const navigate = useCallback(
    (direction: String) => {
      const newDirection = direction === 'next' ? 1 : -1;
      //setPage([page + newDirection, direction]);
      //TODO trigger forward  animation
    },
  );

  useEffect(() => {
    const navigateViaKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          break;
        case 'ArrowRight':
          navigate('next');
          break;
      }
    };

    const navigateViaClick = (direction: String) => {
      navigate(direction);
    };

    const navigateViaClickForward = () => navigateViaClick('next');

    document.addEventListener('keydown', navigateViaKey);
    document.addEventListener('click', navigateViaClickForward);

    return () => {
      document.removeEventListener('keydown', navigateViaKey);
      document.removeEventListener('click', navigateViaClickForward);
    };
  }, [navigate]); */

  return <SlicedFactsSlideBackwards title={title} imageFacts={facts2} imageFacts2={facts} useLightTheme={true} />;
};
