import { motion } from 'framer-motion';
import { styled } from '../../../../styles';
import { highlightColor } from '../../../styles';

export interface DividerProps {
  fromDirection: 'left' | 'right';
  delay?: number;
  // TODO: langju: add prop for color and use this component within thanks slide with white color.
}

export const DividerLine = styled(motion.div)`
  position: absolute;
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

export const DividerBar = styled(motion.div)`
  position: relative;
  height: 0.5em;
  width: 10em;
  margin: 0em 0 -1px 0;
  background: ${highlightColor};
`;

export const DividerLineHalf = styled(motion.div)`
  position: absolute;
  width: 40%;
  margin: 0 0 0 -4.3em;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

export const DividerBarHalf = styled(motion.div)`
  position: relative;
  height: 0.5em;
  width: 9.5em;
  margin: 0em 0 -1px 0;
  background: ${highlightColor};
`;