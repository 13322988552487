import React, { useEffect, useState } from 'react';
import { PresentationFile } from '../../types';
import DropZone from './drop-zone/DropZone';
import { DropZoneFile } from './drop-zone/DropZone.resources';
import KeyboardShortcut from './keyboard-shortcut/KeyboardShortcut';
import {
  StartPresentationProps,
  StartPresentationStyles as Styles,
} from './StartPresentation.resources';

export default function StartPresentation({ onStartPresentation }: StartPresentationProps) {
  const [presentation, setPresentation] = useState<PresentationFile>();

  useEffect(() => {
    const documentElement: any = document.documentElement;
    documentElement.exitFullscreen?.();
  }, []);

  function loadPresentation(file?: DropZoneFile) {
    if (!file) {
      setPresentation(undefined);
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const presentationFile = JSON.parse(e?.target?.result ?? undefined);
      setPresentation(presentationFile);
    };

    reader.readAsText(file as any);
  }

  return (
    <Styles.ViewContainer>
      <Styles.PresentationWizard>
        <Styles.Headline>Präsentation laden</Styles.Headline>
        <DropZone onFileChange={loadPresentation} acceptedFileTypes=".json" />

        {presentation && (
          <Styles.LoadingFeedbackContainer>
            <Styles.Button onClick={() => onStartPresentation?.(presentation)}>
              Präsentation starten
            </Styles.Button>
            <Styles.QuickManual.Container>
              <KeyboardShortcut icon="arrows">
                Pfeiltasten oder klicken zum <strong>Blättern</strong>
              </KeyboardShortcut>
              <KeyboardShortcut icon="esc">
                <strong>Vollbild</strong> schließen
              </KeyboardShortcut>
              <KeyboardShortcut icon="reload">
                Browser aktualisieren oder rechte Maustaste klicken und "Neu laden" wählen, um eine{' '}
                <strong>neue Präsentation zu laden</strong>
              </KeyboardShortcut>
              <KeyboardShortcut icon="fullscreen">
                <strong>Vollbildmodus</strong> aktivieren
              </KeyboardShortcut>
            </Styles.QuickManual.Container>
          </Styles.LoadingFeedbackContainer>
        )}
      </Styles.PresentationWizard>
    </Styles.ViewContainer>
  );
}
