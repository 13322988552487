import React from 'react';
import Presenter from '../../presentation/presenter/Presenter';
import { PresentatorProps, PresentatorStyles as Styles } from './Presentator.resources';

export default function Presentator({ presentation }: PresentatorProps) {
  return (
    <Styles.Container>
      <Presenter slides={presentation.slides} />
    </Styles.Container>
  );
}
