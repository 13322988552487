import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SlideDirection, slideInAnimation, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss } from '../../styled-components';
import { Logo, LOGO_RIGHT_SIDE_SPACING } from '../../styled-components/logo';
import { backgroundColorLight, contentColor, highlightColor } from '../../styles';
import { Theme } from '../../types';
import {subSubTitleTextStyle} from '../../styles';
import { css } from 'styled-components';
import '../../styles/logo-grid.css';

const Slide = styled.div`
  ${BaseSlideCss};

  position: absolute;

  flex-direction: column;

  background: transparent;
  color: ${contentColor};
  text-transform: uppercase;
  font-weight: bold;

  z-index: 20;
  padding: 4.2em ${LOGO_RIGHT_SIDE_SPACING} 4.3em 4.3em;
  box-sizing: border-box;
`;

const Title = styled(motion.div)`
  color: ${highlightColor};
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: -0.002em;
  margin-top: 0.016em;
`;

const TopTitle = styled(motion.div)`
  ${subSubTitleTextStyle}

  color: ${contentColor};
  font-size: 0.8em;
  position: absolute;
  left: 2.4%;
  top: 2.4%;
`;

const Grid = styled(motion.div)`
  height: 13.85em;
  width: 100%;
  // margin-right: 10%;
  // margin-left: 20%;
  // margin-top:4.6em;
  position: absolute;
  right:0;
  top: 47%;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(12, min-content);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 7.5%;
  grid-row-gap: 18%;
`

// const GridImage = styled(motion.div)`
//   ${gridImageStyle};
//   max-height: 50%;
//   object-fit: cover;
//   text-align: right;
// `

// const gridImageStyle = (props: { area: string }) => css`
// `;
 
const GridImage = styled(motion.div)`

`
 const imgStyle = (props: {src: string}) => css`
    position: absolute;
    max-height: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 `
 const StyledImage = styled(motion.img)`
     ${imgStyle};
 `

export const DividerLine = styled(motion.div)`
  position: absolute;
  width: 30%;
  margin: 0 0 0 0;
  border: none;
  border-bottom: 1px solid ${highlightColor};
`;

export const DividerBar = styled(motion.div)`
  position: relative;
  height: 0.35em;
  width: 7.8em;
  margin: 0em 0 -1px 0;
  background: ${highlightColor};
`;

const SubTitleBlock = styled.div`
  width: max-content;
`;

const SubTitle = styled(motion.div)`
  text-transform: uppercase;
  font-size: 3em;
  margin-top: 0.178em;
  letter-spacing: 0.000089em;
`;

interface AwardsSlideProps {
  title: string;
  subtitle: string;
  toptitle: string;
  gridImages: string[];
}

const AwardsSlide: React.FC<AwardsSlideProps> = ({
  title,
  subtitle,
  toptitle,
  gridImages
}) => {
  return (
    <AspectRatio style={{ background: backgroundColorLight }}>
      <Logo theme={Theme.Dark} />
      <Slide>
        <TopTitle initial="hidden" animate = "show">
          {toptitle}
      </TopTitle>
        <Title variants={slideInAnimation()} initial="hidden" animate="show">
          {title}
        </Title>
         <SubTitleBlock>
          <DividerLine variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })} initial="hidden" animate="show">
        <DividerBar
          variants={slideInHalfAnimation({ direction: SlideDirection.FromLeft })}
          initial="hidden"
          animate="show"
        ></DividerBar>
      </DividerLine>
          <SubTitle variants={slideInAnimation({ delay: 0.25 })} initial="hidden" animate="show">
            {subtitle}
          </SubTitle>
        </SubTitleBlock>
        <Grid>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 0.2,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[0]}/>
                </GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 0.4,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[1]}/></GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 0.6,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[2]}/></GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 0.8,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[3]}/></GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 1,
                })}initial="hidden" animate="show">
                <StyledImage src={gridImages[4]}/></GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 1.2,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[5]}/></GridImage>
          <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 1.4,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[6]}/></GridImage>
                <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 1.6,
                })} initial="hidden" animate="show">
                <StyledImage className="opacity" src={gridImages[7]}/></GridImage>
                <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 1.8,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[8]}/></GridImage>
                <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 2,
                })} initial="hidden" animate="show" >
                <StyledImage src={gridImages[9]}/></GridImage>
                <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 2.2,
                })} initial="hidden" animate="show">
                <StyledImage src={gridImages[10]}/></GridImage>
                <GridImage className="logo" variants={slideInAnimation({
                  direction: SlideDirection.FromBottom,
                  delay: 2.4,
                })}initial="hidden" animate="show">
                <StyledImage src={gridImages[11]}/></GridImage>
        </Grid>
      </Slide>
    </AspectRatio>
  );
};

export default AwardsSlide;
