import React from 'react';
import { ImageFact } from '../../types/sliced-fact';
import { SlicedFactsSlide } from '../shared/sliced-pictures-and-facts/SlicedPicturesAndFacts';

interface EmployeeDetailsProps {
  backgroundLeft: string;
  backgroundMiddle: string;
  backgroundRight: string;
  title: string;
  employees: number;
  projectLeads: number; // Projektleitende
  siteManagers: number; // Bauleiter
  foremen: number; // Poliere
}

const EmployeeDetailsSlide: React.FC<EmployeeDetailsProps> = ({
  backgroundLeft,
  backgroundMiddle,
  backgroundRight,
  title,
  employees,
  projectLeads,
  siteManagers,
  foremen,
}) => {
  const facts: ImageFact[] = [
    {
      imageUrl: backgroundLeft,
      facts: [
        {
          description: 'Mitarbeitende',
          value: employees,
        },
      ],
    },
    {
      imageUrl: backgroundMiddle,
      facts: [
        {
          description: 'Projektleitende',
          value: projectLeads,
        },
        {
          description: 'Facharbeiter',
          value: siteManagers,
        },
      ],
    },
    {
      imageUrl: backgroundRight,
      facts: [
        {
          description: 'Auszubildende',
          value: foremen,
        },
      ],
    },
  ];

  return <SlicedFactsSlide title={title} imageFacts={facts} useLightTheme={true} />;
};

export default EmployeeDetailsSlide;
